import { createStyles } from '@material-ui/core/styles'

export default () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 80,
    },
    logoFull: {
      marginLeft: 50,
      width: 150,
      height: 'auto',
      objectFit: 'contain',
    },
    logoIsotype: {
      width: 70,
      height: 'auto',
      objectFit: 'contain',
    },
  });