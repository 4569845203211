import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import logo from '../../../assets/images/velora.svg'
import { AuthUtils } from '../../../redux/auth'
import { recoverPassword } from "../../../api";
import { toast } from "react-toastify";
import { TOKEN_KEY } from '../../../config/constants'

const Login = ({ onSubmit, classes, i18n, isFetching, location }) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [passwordRecover, setPasswordRecover] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('isLogout')) {
      localStorage.removeItem('isLogout');
      window.location.href = '/login';
    }

    let params = new URLSearchParams(location.search);
    if (params.has('access_token')) {
      let access_token = params.get('access_token');
      if (access_token) {
        localStorage.setItem(TOKEN_KEY, access_token);
        localStorage.setItem('backLocation', '/talent_acquisition/inbox?keepFilters=1');
        if (params.has('login_url')) {
          localStorage.setItem('login_url', params.get('login_url'));
        }

        const date = new Date();
        localStorage.setItem('valid_token', date.setDate(date.getDate() + 30));
        window.location.href = location.pathname.split('?').shift();
      }
    }

    setTimeout(() => setShowLoginForm(true), 300);
  }, []); // eslint-disable-line

  const validateForm = (isPasswordRecover) => {
    const newErrors = {}
    if (!email) {
      newErrors.email = i18n.login.mandatoryField
    } else if (!AuthUtils.isValidEmail(email)) {
      newErrors.email = i18n.login.invalidEmail
    }

    if (!isPasswordRecover && !password) {
      newErrors.password = i18n.login.mandatoryField
    }

    setErrors(newErrors)
    return _.isEmpty(_.keys(newErrors))
  }

  const handleSubmit = event => {
    event.preventDefault()

    if (validateForm(passwordRecover)) {
      if (!passwordRecover) {
        onSubmit({ email, password }).catch(error => {
          setErrors({ email: error.message })
        })
      } else {
        recoverPassword(email).finally(() => {
          toast(i18n.login.password_recover_success, { type: "success" });
          toggleAction();
        });

        // TODO: Utilizar esta versión al arreglar error de cors en la petición.
        // recoverPassword(email).then(() => {
        //   toast(i18n.login.password_recover_success, {type: "success"});
        //   toggleAction();
        // }).catch(() => {
        //   toast(i18n.login.password_recover_error, {type: "error"});
        // });
      }
    }
  }

  const toggleAction = () => {
    setPasswordRecover(!passwordRecover);
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      {showLoginForm &&
        <Paper className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <FormHeader classes={classes} i18n={i18n} isPasswordRecover={passwordRecover} />
            <EmailField value={email} onChange={setEmail} error={errors.email} i18n={i18n} disabled={isFetching} classes={classes} />
            {!passwordRecover && <PasswordField value={password} onChange={setPassword} error={errors.password} i18n={i18n}
              disabled={isFetching} classes={classes} />}
            <SubmitButton classes={classes} i18n={i18n} isFetching={isFetching} isPasswordRecover={passwordRecover} />
          </form>
          <p style={{ cursor: "pointer", color: "#0176BC" }} onClick={!isFetching && toggleAction}>
            {passwordRecover ? i18n.login.login : i18n.login.password_recover}
          </p>
        </Paper>
      }
    </Container>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
  classes: PropTypes.object,
  i18n: PropTypes.object
}

const FormHeader = ({ classes, i18n }) => (
  <div className={classes.header}>
    <Avatar className={classes.avatar}>
      <img src={logo} alt="velora-logo" />
    </Avatar>
    <Typography component="h1" variant="h6" style={{ fontFamily: 'Poppins, sans-serif' }}>
      {i18n.login.title}
    </Typography >
  </div>
)

FormHeader.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.object
}

const SubmitButton = ({ classes, i18n, isFetching, isPasswordRecover }) => (
  <Button type="submit" fullWidth variant="contained" style={{
    backgroundColor: '#4DDCD8',
    color: '#fff',
  }} className={classes.submit} disabled={isFetching}>
    {isPasswordRecover ? i18n.login.recover : i18n.login.sigin}
    {isFetching ? <CircularProgress style={{ color: 'white' }} className={classes.progress} size={24} /> : null}
  </Button>
)

SubmitButton.propTypes = {
  classes: PropTypes.object,
  i18n: PropTypes.object,
  isFetching: PropTypes.bool
}

const EmailField = ({ value, onChange, error, i18n, disabled, classes }) => (
  <TextField
    disabled={disabled}
    error={!_.isEmpty(error)}
    helperText={error}
    variant="outlined"
    margin="normal"
    required
    InputLabelProps={{
      shrink: true,
      classes: {
        focused: classes.customLabelFocused,
      },
    }}
    className={classes.customNotchedOutline}
    fullWidth
    id="email"
    label={i18n.login.email}
    name="email"
    autoComplete="email"
    value={value}
    onChange={e => onChange(e.target.value)}

  />
)

EmailField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  i18n: PropTypes.object,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
}

const PasswordField = ({ value, onChange, error, i18n, disabled, classes }) => (
  <TextField
    disabled={disabled}
    error={!_.isEmpty(error)}
    helperText={error}
    variant="outlined"
    margin="normal"
    required
    InputLabelProps={{
      shrink: true,
      classes: {
        focused: classes.customLabelFocused,
      },
    }}
    className={classes.customNotchedOutline}
    fullWidth
    name="password"
    label={i18n.login.password}
    type="password"
    id="password"
    autoComplete="current-password"
    value={value}
    onChange={e => onChange(e.target.value)}
  />
)

PasswordField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  i18n: PropTypes.object,
  disabled: PropTypes.bool
}

export default Login
