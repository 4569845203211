export default {
  generalError: 'Une erreur s\'est produite',
  update: 'Actualiser',
  showMore: 'Afficher plus +',
  showLess: 'Afficher moins -',
  markAs: 'Marquer comme',

  genericText: {},

  modalGeneric: {
    cancel: 'Annuler',
    send: 'Envoyer'
  },

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Appliquer'
  },

  regularSelect: {
    select: 'Sélectionner'
  },

  modalHeader: {
    planning: 'Planification'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Éliminer',
    enterCandidateName: 'Introduire le nom du candidat pour confirmer l\'action',
    writeCandidateName: 'Écrivez le nom de la personne',
    filterProfiles: 'Filtrer les profils',
    remove: 'Éliminer'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Ajouter à la liste noire',
    removeFromBlacklist: 'Éliminer de la liste noire',
    enterCandidateName: 'Introduire le nom du candidat pour confirmer l\'action',
    writeCandidateName: 'Écrivez le nom de la personne',
    filterProfiles: 'Filtrer les profils',
    add: 'Ajouter',
    remove: 'Éliminer'
  },

  modalChangeStatus: {
    changeStatus: 'Modifier le statut',
    selectStatus: 'Sélectionner un statut',
    selectOffer: 'Sélectionner un poste',
    incomplete_form: 'Vous devez sélectionner le statut et le poste pour pouvoir réaliser cette action',
    select: 'Sélectionner',
    suitable: 'Apte',
    not_suitable: 'Inapte',
    not_evaluated: 'Non évalué',
    to_be_confirmed: 'À confirmer',
    confirmed: 'Confirmé',
    not_interested: 'Non intéressé',
    reschedule: 'Reprogrammer',
    hired: 'Engagé',
    booking: 'Réservation',
    not_attend: 'Ne participe pas',
    cited_phase2: 'Convoqué phase 2',
    pending_update: 'Actualisation en cours',
    discarded: 'Rejeté',
    descarded_phase2: 'Rejeté',
    not_interested_phase2: 'Non intéressé phase 2',
    not_attend_phase2: 'Ne participe pas phase 2',
    in_process: 'En cours',
    hiredDate: 'Date de recrutement',
    in_progress: 'Progression en cours',
    doc_required: 'Doc requis',
    doc_received: 'Doc reçu'
  },

  modalChangeTags: {
    title: 'Modifier les étiquettes'
  },

  favoritesTable: {
    date: 'Date',
    markAsRead: 'Marquer comme lu',
    changeState: 'Modifier le statut',
    markFavorite: 'Marquer favori',
    removeFavorites: 'Éliminer favoris',
    fav: 'Favori',
    export: 'Exporter',
    profile: 'Notation profil',
    skills: 'Notation compétences',
    matching: 'Matching total',
    status: 'Statut',
    evaluation: 'Évaluation',
    personalData: 'Données personnelles'
  },

  favoritesProcessTable: {
    title: 'Processus favoris'
  },

  favoritesOnboardingTable: {
    title: 'Intégration des favoris'
  },

  processesTable: {
    title: 'Processus Inbox',
    id: 'Processus',
    name: 'Prénom et nom',
    prominent: 'Remarquable',
    date: 'Date et heure',
    department: 'Département, secteur',
    category: 'Catérgorie',
    type: 'Type',
    contact: 'Email',
    tel: 'Téléphone',
    status: 'Statut',
    markFavorite: 'Marquer favori',
    removeFavorites: 'Éliminer favori',
    print: 'Imprimer',
    markAsRead: 'Marquer comme lu',
    unmarkAsRead: 'Marquer comme non lu',
    filterProcesses: 'Filtrer processus',
    changeStatus: 'Modifier le statut',
    changeTags: 'Modifier étiquettes',
    offer: 'Envoyer offre',
    assignProcess: 'Attribuer un processus',
    offerCandidatureState: 'Intérêts',
    export: 'Créer rapport',
    all: 'Tous',
    talent_acquisition: 'candidatures sélectionnées',
    on_board: 'employés sélectionnés',
    welcome: 'employés sélectionnés',
    employee_line: 'employés sélectionnés',
    feedback: 'employés sélectionnés',
    exit_interview: 'employés sélectionnés',
    offer_sent: 'Offres envoyées',
    statusChanged: 'Statut modifié avec succès'
  },

  offerModal: {
    title: 'Créer offre push',
    name: 'Nom de l\'offre',
    name_placeholder: 'Livreur Aranjuez',
    description: 'Description',
    description_placeholder: 'Nous recherchons une personne ayant 2 ans d\'expérience et un véhicule pour le secteur d\'Aranjuez',
    date_range: 'Date de début/fin de l\'offre',
    areUSure: {
      description: 'Vous allez informer {num} personne/s de cette offre, Souhaitez-vous continuer?',
      sendCompleted: 'Offre envoyée'
    }
  },

  assignJobOfferModal: {
    title: 'Attribuer poste',
    offer: "Poste",
    offer_placeholder: "Choisissez le poste auquel vous souhaitez affecter",
    button_title: "Attribuer un poste",
    assign: "Attribuer",
    successfully_assigned: "Les candidats ont été correctement assignés au poste indiqué",
    areUSure: {
      description: 'Vous allez attribuer {num} candidat/s à ce poste, souhaitez-vous continuer ?',
      sendCompleted: 'Poste attribué'
    },
  },

  assignedModal: {
    title: 'Attribuer un processus',
    assign: 'Attribuer',
    subModalTitle: 'Confirmez l\'attribution du processus?',
    subModalSubTitle: 'Candidatures:',
    subModalSubDescription: 'Processus sélectionné:',
    latest: 'Récents',
    placeholder: 'Rechercher par nom de processus'
  },

  process: {
    title: 'Processus',
    category: 'Catérgorie',
    department: 'Département',
    service: 'Service',
    process: 'Processus',
    comments: 'Remarques',
    date: 'Date',
    time: 'Heure',
    comment: 'Remarque',
    author: 'Auteur',
    documentation: 'Documentation présentée',
    previewCV: 'CV du candidat',
    noPreview: 'Aperçu non disponible',
    downloadDocs: 'Télécharger sélectionnés',
    uploadDocs: 'Télécharger documents',
    createComment: 'Créer remarque',
    text: 'Texte',
    commentName: 'Remarque',
    dayHourTransaction: 'Date et heure transaction',
    area: 'Secteur',
    centerProvince: 'Centre Province',
    centerType: 'Type de centre',
    registerData: 'Date de recrutement',
    noDocuments: 'Aucun document',
    modifiedStatus: 'Statut modifié',
    deleteSuccess: 'Le processus a été éliminé avec succès',
    deleteModalTitle: 'Attention !',
    deleteModalText: 'Êtes-vous sûr de souhaiter éliminer cet élément ?',
    remove: 'Éliminer',
    previewVideo: 'Vidéos'
  },

  modalAnnouncement: {
    quoteCall: 'Organiser convocation',
    editQuoteCall: 'Convocation',
    date: 'Date d\'envoi',
    citationStatus: 'Réponse',
    citationDate: 'Date de convocation',
    citationHour: 'Heure de convocation',
    interviewer: 'Recruteur',
    location: 'Lieu',
    select: 'Sélectionner',
    announcement_details: 'Détails',
    writeDetails: 'Préciser les détails de la convocation',
    writeDetailsIntegration: 'Préciser les détails de la convocation (ne pourra pas être modifié après avoir créé l\'événement)',
    body: 'Corps',
    writeBody: 'Écrire le corps du message',
    defaultMessages: 'Messages prédéfinis',
    sendCitation: 'Envoyer convocation',
    updateCitation: 'Actualiser',
    writeLocation: 'Écrire le lieu',
    writeInterviewer: 'Écrire le nom du recruteur',
    new_event: 'Objet',
    invite_people: 'Parcipants facultatifs',
    invite_people_placeholder: 'Comprend les emails séparés par ;',
    to_time: 'jusqu\'à',
    online_meeting: 'Réunion en ligne',
    attendees: 'Participants',
    optionals: 'Facultatifs',
    accepted: 'Acceptée',
    declined: 'Rejetée',
    rejected: 'Rejetée',
    none: 'Sans réponse',
    pending: 'Sans réponse',
    tentative: 'Provisoire',
    organizer: 'Organisateur',
    notes: 'Notes',
    confirmation: {
      description_create_individual: 'Vous allez créer une convocation individuelle, souhaitez-vous continuer ?',
      description_create_group: 'Vous allez créer une convocation de groupe pour {num} personnes, souhaitez-vous continuer ?',
      description_update: 'Vous allez modifier une convocation pour {num} personne/s, les convocations originales seront éliminées, souhaitez-vous continuer ?',
      description_update_one: 'Une convocation existe déjà, les données seront éliminées, souhaitez-vous continuer ?'
    },
    filterBlockCitation: 'Convocation',
    filterBlockGeneral: 'Générale',
    responsible: 'Responsable'
  },

  modalRequireDoc: {
    requireDoc: 'Demander documentation',
    limitDate: 'Date limite de présentation de la documentation',
    select: 'Sélectionner',
    infoToUpload: 'Informations à présenter',
    writeInfoToUpload: 'Précise les informations à présenter par le candidat',
    body: 'Corps',
    writeBody: 'Écrire le corps du message',
    defaultMessages: 'Messages prédéfinis',
    sendRequireDoc: 'Envoyer'
  },

  modalMailCustom: {
    quoteCall: 'Envoyer notification',
    editQuoteCall: 'Modifier convocation',
    subject: 'Objet',
    writeSubject: 'Écrivez l\'objet',
    message: 'Message',
    writeMessage: 'Écrivez un message',
    body: 'Corps',
    writeBody: 'Écrire le corps du message',
    defaultMessages: 'Messages prédéfinis',
    send: 'Envoyer notification',
    sendCompleted: 'Notification envoyée',
    sendsCompleted: 'Notifications envoyées'
  },

  modalFilter: {
    search: 'Rechercher',
    notResults: 'Aucun résultat',
    filterProfiles: 'Filtrer les profils',
    name: 'Nom',
    candidature: 'Poste',
    namePlaceholder: 'Nom du candidat',
    date: 'Date',
    fromDate: 'Du',
    toDate: 'Au',
    profile: 'Profil',
    skills: 'Compétences',
    recruitmentSource: 'Origine de recrutement',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'Du',
    to: 'Au',
    inputTextHelper: 'brecherche de coïncidences multiples. Ej: "Madrid;Barcelona"',
    matching: 'Matching',
    workingDayLabel: 'Journée de travail',
    workingDayPlaceholder: '10 heures, 20 heures...',
    select: 'Sélectionner',
    availability: 'Disponibilité',
    availabilityPlaceholder: 'Complète, partielle...',
    actualStatus: 'Statut actuel',
    actualStatusPlaceholder: 'Apte, inapte...',
    position: 'Poste',
    positionPlaceholder: 'Vendeur, serveur...',
    academicFormation: 'Formation universitaire',
    academicFormationPlaceholder: 'Diplôme, Licence...',
    experience: 'Expérience',
    experiencePlaceholder: '1 an, 2 ans...',
    lastJob: 'Dernier poste',
    lastJobPlaceholder: 'Vendeur, serveur...',
    cityOfResidence: 'Adresse',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Données complémentaires',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Autres données',
    desiredAreaPlaceholder: 'Centre, Usera...',
    saveSearch: 'Sauvegarder la recherche filtrée',
    status: 'Statut',
    planningFilters: 'Filtres de planification',
    candidatureFilters: 'Filtres du candicat'
  },

  boxOtherInfo: {
    otherData: 'Autres données',
    additionalInformation: 'Informations complémentaires',
    nearbyPlaces: 'Centres les plus proches'
  },

  boxEditableInfo: {
    title: 'Informations modifiables',
    edit: 'Modifier',
    cancel: 'Annuler',
    accept: 'Accepter',
    success: 'Champs mis à jour avec succès',
    error: 'Erreur de mise à jour des champs'
  },

  editFieldAction: {
    title: 'Modifier champ',
    empty: '(Aucune valeur)'
  },

  videoSentiments: {
    title: 'Analyse de la vidéo',
    titleValores: 'Sentiments détectés',
    anger: 'Colère',
    contempt: 'Mépris',
    disgust: 'Répugnance',
    fear: 'Peur',
    happiness: 'Bonheur',
    neutral: 'Neutralité',
    sadness: 'Tristesse',
    surprise: 'Surprise'
  },

  citations: {
    citationCreated: 'Convocation créée',
    citationUpdated: 'Convocation mise à jour',
    citationError: 'Erreur de création de convocation',
    citations_sent: 'Convocations envoyées',
    candidatures: 'Candidats'
  },

  citationStatus: {
    rejected: 'Rejeté',
    accepted: 'Accepté',
    replan: 'Replanifier',
    pending: 'Sans réponse',
    tentative: 'Provisoire'
  },

  requireDoc: {
    docRequired: 'Demande de documentation envoyée',
    docRequest: 'Documentation demandée',
    errorDocRequire: 'Erreur de demande de la documentation'
  },
  interviewerNotes: {
    titleTab: 'Notes sur l\'entretien',
    edit: 'Modifier notes',
    textareaPlaceholder: 'Notes',
    showOnPrint: 'Afficher en imprimant',
    updatedAt: 'Modifié par {auteur}, le {date} à {heure}'
  },

  headerProfileButtons: {
    export: 'Exporter',
    back: 'Retour',
    actions: 'Actions',
    previous: 'Précédent',
    following: 'Suivant',
    downloadCV: 'Télécharger CV',
    downloadVideo: 'Vidéo jointe',
    markAsRead: 'Marquer comme lu',
    quoteCall: 'Organiser convocation',
    editQuoteCall: 'Convocation',
    changeState: 'Modifier le statut',
    changeTags: 'Modifier étiquettes',
    markFavorite: 'Marquer favori',
    removeFavorites: 'Éliminer favoris',
    exportFavorites: 'Exporter excel',
    remove: 'Éliminer',
    print: 'Imprimer',
    userDeleted: 'Utilisateur éliminé',
    files: 'Jointes',
    mailCustom: 'Envoyer notification',
    docs: 'Jointes',
    backToApplications: 'Retour à la candidature',
    add_to_blacklist: 'Ajouter à la liste noire',
    remove_from_blacklist: 'Retirer de la liste noire',
    added_to_blacklist: 'Ajouté à la liste noire',
    removed_from_blacklist: 'Retiré de la liste noire',
    backToProcess: 'Retour au processus',
    backToEvaluativeTests: 'Retour à l\'entretien vidéo',
    statusChanged: 'Statut modifié avec succès',
    personDeleted: 'Personne éliminée avec succès',
    deletePersonError: 'Erreur lors de l\'élimination de la personne'
  },

  modalDownloadFiles: {
    title: 'Jointes'
  },

  modalFilesPreview: {
    no_preview: 'Aperçu non disponible'
  },

  modalCreateReport: {
    createReport: 'Créer rapport',
    name: 'Nom',
    reportName: 'Nom du rapport',
    date: 'Date',
    fromDate: 'Du',
    toDate: 'Au',
    profile: 'Profil',
    skills: 'Compétences',
    recruitmentSource: 'Origine de recrutement',
    matching: 'Matching',
    workingDay: 'Journée de travail',
    availability: 'Disponibilité',
    actualStatus: 'Statut actuel',
    position: 'Poste',
    positionPlaceholder: 'Vendeur, serveur...',
    academicFormation: 'Formation universitaire',
    academicFormationPlaceholder: 'Diplôme, Licence...',
    experience: 'Expérience',
    lastJob: 'Dernier poste',
    lastJobPlaceholder: 'Vendeur, serveur...',
    cityOfResidence: 'Adresse',
    cityOfResidencePlaceholder: 'Madrid, Barcelone...',
    desiredLocation: 'Données complémentaires',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Autres données',
    desiredAreaPlaceholder: 'Centre, Usera...',
    removeUser: 'Éliminer utilisateur',
    enterCandidateName: 'Introduire le nom du candidat pour confirmer l\'action',
    writeCandidateName: 'Écrire le nom du candidat',
    filterProfiles: 'Filtrer les profils',
    from: 'De',
    to: 'À',
    include: 'Inclure',
    selectAll: 'Tout sélectionner'
  },

  modalChangeAvatar: {
    title: 'Modifier avatar',
    info: 'Faites glisser une image ou cliquez sur la case pour la chercher',
    submit: 'Accepter',
    dropFilesHere: 'Faites glisser une image ici, ou cliquez pour sélectionner des fichiers...',
    dropFilesActive: 'Image acceptée, déposez-la pour continuer'
  },

  modalChangePass: {
    title: 'Modifier le mot de passe',
    forceTitle: 'Pour continuer, modifiez le mot de passe',
    info: 'Introduisez le nouveau mot de passe et appuyez sur Accepter',
    submit: 'Accepter',
    oldPass: 'Mot de passe actuel',
    newPass: 'Nouveau mot de passe',
    repPass: 'Répétez le mot de passe',
    errors: {
      passNotMatch: 'Les mots de passe ne coïncident pas',
      passNotStrongEnough: "Le mot de passe doit contenir au moins 12 caractères et inclure une combinaison de majuscules, minuscules, chiffres et caractères spéciaux."
    }
  },

  boxProfileCite: {
    currentState: 'Statut actuel',
    citationDate: 'Date convocation',
    hiringDate: 'Date de début de contrat',
    citationManager: 'Responsable convocation',
    recruitmentSource: 'Origine de recrutement',
    tags: 'Étiquettes',
    offers: 'Offres envoyées',
    disponibilidad: 'Disponibilité horaire :'
  },

  boxInfoHeader: {
    currentState: 'Statut actuel',
    citationDate: 'Date convocation',
    citationManager: 'Responsable convocation'
  },

  modules: {
    title: 'Modules',
    module1: 'Acquisition de talents',
    module2: 'Intégration',
    module3: 'Pack de bienvenue',
    module4: 'Hiérarchie de l\'employé',
    module5: 'Absentisme',
    module6: 'Études',
    module7: 'Tuteur visuel',
    module8: 'entretien entretien'
  },

  module_names: {
    talent_acquisition: 'Captation de talent',
    employee_line: 'Hiérarchie de l\'employé',
    on_board: 'Intégration',
    welcome: 'Bienvenue',
    feedback: 'Retour',
    abseentism: 'Absentisme',
    survey: 'Enquête',
    visual: 'Visuel',
    exit_interview: 'Sortir de l\'entretien'
  },

  myProfile: {
    title: 'Mon profil',
    logOut: 'Fermer la session',
    name: 'Nom',
    surname: 'Nom',
    email: 'Email',
    id: 'Id',
    client: 'Identifiant du client',
    phone: 'Téléphone',
    profileUpdated: 'Profil mis à jour',
    avatarUpdated: 'Avatar mis à jour',
    updatePass: 'Mettre à jour le mot de passe',
    update: 'Actualiser',
    passUpdated: 'Mot de passe mis à jour',
    passUpdatedError: 'Le mot de passe n\'a pas été mis à jour. Veuillez vérifier que le mot de passe est correct.',
    changeLanguage: 'Modifier la langue',
    languages: [
      { value: 'es', name: 'Español' },
      { value: 'en', name: 'Inglés' },
      { value: 'ca', name: 'Catalán' },
      { value: 'pt', name: 'Portugues' },
      { value: 'fr', name: 'Français' }
    ]
  },

  simpleDialog: {
    defaultMessages: 'Messages prédéfinis',
    close: 'Fermer'
  },

  saveSearchesTable: {
    actions: 'Actions',
    delete: 'Éliminer',
    title: 'Titre',
    date: 'Date',
    fields: 'Champs de filtre',
    backToSaveSearches: 'Retour aux recherches sauvegardées'
  },

  pullConfig: {
    title: 'Pull des candidats',
    candidatures: 'Candidats notifiés'
  },

  pullConfigTable: {
    actions: 'Actions',
    delete: 'Éliminer',
    title: 'Titre',
    is_active: 'Actif',
    renotify_answered: 'Notifier de nouveau',
    interval_in_days: 'Fréquence',
    days: 'Jours',
    last_time_execution: 'Dernier envoi',
    filters: 'Champs de filtre',
    from: 'Du',
    to: 'au'
  },

  reportsTable: {
    actions: 'Actions',
    delete: 'Éliminer',
    title: 'Titre',
    date: 'Date',
    fields: 'Champs de filtre'
  },

  profileStats: {
    profile: 'Profil',
    skills: 'Compétences'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Risque',
    e: 'Extroversión',
    p: 'Pacience',
    n: 'Règles',
    a: 'Autocontrôle'
  },

  modalSaveSearch: {
    title: 'Nom pour la recherche sauvegardée',
    back: 'Retourner au filtre',
    enterName: 'Écrivez un nom',
    saveSearch: 'Sauvegarder la recherche filtrée'
  },

  modalSearch: {
    filters: 'Filtres appliqués :',
    title: 'Filtrer processus',
    chipGroupLocation: 'Lieu',
    chipGroupProcess: 'Processus',
    chipGroupPreferences: 'Préférences',
    workCenter: 'Centre de travail',
    processStatus: 'Statut du processus',
    applicant: 'Demandeur',
    validator: 'Valideur',
    creationDate: 'Date de création',
    expectedDateOfIncorporation: 'Date prévue de début',
    position: 'Poste',
    typeOfContract: 'Type de contrat',
    workingDay: 'Journée de travail',
    reason: 'Motif',
    brand: 'Marque',
    shift: 'Équipe',
    placeholderSelect: 'Sélectionner',
    from: 'Du',
    to: 'au',
    find: 'Rechercher',
    clearFilter: 'Nettoyer filtres'
  },

  planningTable: {
    markAsRead: 'Marquer comme lu',
    quoteCall: 'Organiser convocation',
    editQuoteCall: 'Modifier convocation',
    changeState: 'Modifier le statut',
    markFavorite: 'Marquer favori',
    removeFavorites: 'Éliminer favoris',
    exportFavorites: 'Exporter CSV',
    remove: 'Éliminer',
    print: 'Imprimer',
    actions: 'Actions',
    personalData: 'Données personnelles',
    candidature: 'Candidature',
    fav: 'Favori',
    export: 'Exporter',
    matching: 'Matching',
    citation: 'Date entretien',
    interviewer: 'Recruteur',
    atendees: 'Participants',
    phase: 'Phase',
    statusPhase: 'Statut',
    citationStatus: 'Réponse',
    status: 'Suivi',
    location: 'Lieu',
    technical: 'Organisateur',
    date: 'Date d\'envoi',
    availability: 'Disponibilité',
    reason: 'Raison',
    groupal: 'Groupe',
    view: 'Voir'
  },

  peopleTable: {
    markAsRead: 'Marquer comme lu',
    markAsNotRead: 'Marquer comme non lu',
    quoteCall: 'Organiser convocation',
    editQuoteCall: 'Modifier convocation',
    changeState: 'Modifier le statut',
    markFavorite: 'Marquer favori',
    removeFavorites: 'Éliminer favoris',
    exportFavorites: 'Exporter CSV',
    remove: 'Éliminer',
    print: 'Imprimer',
    actions: 'Actions',
    personalData: 'Données personnelles',
    fav: 'Favori',
    export: 'Exporter cv',
    exportCsv: 'Exporter rapport',
    requireDoc: 'Demander documentation'
  },

  landingOffer: {
    title: 'Bonjour',
    body: 'Nous avons une nouvelle <b>offre de travail</b> qui pourrait vous intéresser : ',
    offerInfo: 'Informations concernant l\'offre',
    interested: 'L\'offre m\'intéresse',
    notInterested: 'L\'offre ne m\'intéresse pas',
    notExist: 'L\'offre n\'existe pas',
    expiredDescription: 'Nous sommes désolés, cette offre est déjà close. Le tendremos en cuenta para furturas oportunidades. <br>Merci beaucoup pour votre intérêt.',
    endDescription: 'C\'est parfait ! Nous vous confirmons que nous avons reçu votre réponse. <br>Merci beaucoup d\'avoir participé à notre processus de sélection'
  },

  landingPull: {
    title: 'Bonjour',
    interested: 'L\'offre m\'intéresse',
    notInterested: 'L\'offre ne m\'intéresse pas',
    notExist: 'Page non trouvée',
    endDescription: 'C\'est parfait ! Nous vous confirmons que nous avons reçu votre réponse. <br>Merci beaucoup'
  },

  landingDocRequest: {
    title: 'Veuillez joindre les informations demandées : ',
    limit_date: 'Date limite : ',
    notExist: 'Page non trouvée',
    expiredDescription: 'Nous sommes désolés, le délai de présentation des informations a déjà expiré. <br>Merci beaucoup pour votre intérêt.',
    endDescription: 'C\'est parfait ! Nous vous confirmons avoir reçu les informations. <br>Merci beaucoup !'
  },

  dropzone: {
    dragOrBrowse: 'Faites glisser les fichiers ou cliquez pour les sélectionner',
    addFile: 'Ajouter fichier',
    send: 'Envoyer',
    error: 'Erreur',
    file_too_large: 'Fichier trop lourd',
    file_max_size: 'Taille maximum du fichier',
    done: 'Envoyé'
  },

  observationsTable: {
    today: 'Aujourd\'hui',
    now: 'Maintenant',
    title: 'Historique des changements',
    date: 'Date',
    time: 'Heure',
    change: 'Changement',
    author: 'Auteur',
    addPlaceholder: 'Ajouter ici une nouvelle remarque',
    add: 'Ajouter',
    interviewDate: 'Date entretien',
    addObservation: 'Ajouter des remarques',
    deleted_user_name: 'Usager',
    deleted_user_surname: 'supprimé',
  },

  pushOfferTable: {
    title: 'Offres Push',
    name: 'Nom',
    description: 'Description',
    userName: 'Utilisateur',
    createdAt: 'Date création',
    state: 'Statut'
  },

  lastCitationsTable: {
    title: 'Dernières convocations',
    date: 'Date d\'envoi',
    interviewDate: 'Date entretien',
    userName: 'Organisateur',
    location: 'Lieu',
    statusAnswer: 'Réponse',
    editCitation: 'Modifier convocation',
    attendees: 'Participants',
    reason: 'Motif',
  },

  offerCard: {
    title: 'Offres Push',
    interested_candidatures: ' candidats intéressés',
    update_at: 'Date',
    empty: 'Il n\'existe actuellement aucune offre push',
    showOffers: 'Afficher uniquement mes offres push',
    delete: {
      success: 'L\'offre a été effacée avec succès',
      error: 'Une erreur s\'est produite en effaçant l\'offre'
    }
  },

  //Organism
  appBar: {
    searchPeople: 'Rechercher des profils',
    filterPeople: 'Filtrer candidatures',
    filterPlanning: 'Filtrer planification',
    saveSearchs: 'Sauvegarder recherches',
    saveSearch: 'Sauvegarder recherche',
    cloneSearch: 'Cloner recherche',
    noResults: 'Aucun résultat',
    errorNoTitle: 'Le titre est obligatoire',
    filters: 'Filtres',
    successSave: 'Recherche sauvegardée'
  },

  profileInfo: {
    profile: 'Profil',
    availableFrom: 'Disponible à partir du :',
    createdAt: 'Date de l\'entretien',
    updatedAt: 'Dernière mise à jour',
    pullSentAt: 'Envoi de pull',
    zone: 'Secteur géographique',
    formation: 'Formation universitaire',
    exp: 'Expérience',
    lastJob: 'Dernier poste',
    city: 'Adresse',
    desiredLocation: 'Données complémentaires',
    desiredZone: 'Secteur',
    years: 'années',
    cv: 'CV',
    video: 'Vidéo',
    attached: 'Joint',
    evaluation: 'Évaluation',
    selectionStatus: 'Modifier le statut actuel'
  },

  drawer: {
    jobOffers: 'Postes',
    inboxPeople: 'Candidatures',
    inbox: 'Candidatures',
    savedSearches: 'Recherches sauvegardées',
    planning: 'Planification',
    favorites: 'Favoris',
    reports: 'Rapports',
    statistics: 'Statistiques',
    dashboard: 'Tableau de bord',
    modules: 'Modules',
    clients: 'Clients',
    employeeLine: 'Hiérarchie employé',
    talent: 'Captation de talent',
    pushOffer: 'Offres Push',
    pullConfig: 'Configuration Pull',
    onboarding: 'Intégration',
    feedback: 'Suggestions',
    exit_interview: 'Entretien de départ',
    integrations: 'Calendriers',
    my_profile: 'Mon profil',
    userManual: 'Manuel utilisateur ATS',
    phases_states: 'Phases et statuts',
    evaluativeTests: 'Entretiens vidéos',
    user_management: 'Utilisateurs',
    tag_management: 'Etiquettes',
    keywords_rpa: 'Jobsites',
    partners: 'Partenaires',
    superadministratorManual: 'Manuel administrateur ATS',
    iaEvaluation: 'Évaluation IA',
    templateRequestManager: 'Gestionnaire de demande de modèle',
    validatorTemplateRequest: 'Validation de processus'
  },

  statisticsItems: {
    checkAll: 'Marquer tous',
    year: 'Année',
    month: 'Mois',
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    interviewed: 'Candidats interviewé',
    suitables: 'Aptes vs interviewés',
    offer_interested: 'Intéressés par les offres',
    recovered: 'Candidats mis à jour',
    recruitmentSource: 'Candidats par origine de recrutement',
    activity: 'Activité des candidats interviewés',
    startDate: 'Du',
    endDate: 'au',
    candidatures: 'Candidatures',
    name: 'Nom',
    surname: 'Nom',
    dni: 'Nº identité DNI',
    numberIdentity: 'Nº employé',
    hiringDate: 'Date début de contrat',
    leavingDate: 'Date départ',
    employeesWithoutPhase1: 'Personnes qui n\'ont passé aucune phase d\'intégration',
    employeesHaveNotDoneExit: 'Employés qui n\'ont pas passé l\'entretien de départ',
    candidaturesSuitables: 'Candidatures aptes',
    hiredVsSuitables: 'Engagés vs aptes',
    notInterestedVsSuitables: 'Non intéressés vs aptes',
    tableTitle: 'Positions',
    positions: 'Positions',
    onboarding_phases: 'Personnes actives dans chaque phase de l\'intégration',
    phases: 'Phases',
    zone: 'Secteur',
    alta: 'Actuellement en poste',
    baja: 'Départ enregistré',
    whatsappCounter: {
      title: 'Compteur de WhatsApp',
      section: 'Section',
      bot_starter: 'Invitations entretien',
      citations: 'Convocations',
      offers: 'Offres push',
      pulls: 'Pull des candidats',
      sent: 'Envoyés',
      voucher: 'Bon'
    }
  },

  //Page
  inboxPeople: {
    title: 'Candidatures',
    filtersSearch: 'Résultats pour'
  },

  offerCandidates: {
    title: 'Offre : ',
    offers_sent: 'Offres envoyées',
    unique_candidatures: 'Candidats uniques',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'WhatsApp'
  },

  favorites: {
    title: 'Favoris'
  },

  planning: {
    title: 'Planification'
  },

  reports: {
    title: 'Rapports',
    reportCreated: 'Rapport créé',
    reportDeleted: 'Rapport effacé',
    modalConfirmationTitle: 'Générer rapport',
    modalConfirmationButton: 'Accepter',
    modalConfirmationButtonCancel: 'Annuler',
    reportReceiversTitle:
      'Introduisez les destinataires du rapport. Este proceso puede demorarse unos minutos. Te enviaremos un correo cuando este listo. Vous pouvez ajouter plusieurs destinataires en les séparant par <b>;</b>',
    reportExportedSuccess: 'Votre rapport est en cours de création. Nous l\'enverrons par courrier au plus tôt',
    reportExportedFailed: 'Une erreur s\'est produite lors de la création de votre rapport. L\'incident a été communiqué à l\'équipe de support'
  },

  savedSearches: {
    title: 'Recherches sauvegardées'
  },

  exitInterview: {
    title: 'Entretiens de départ'
  },

  statistic: {
    title: 'Statistiques',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche'
  },

  errorPage: {
    title: 'Whoups ! Page non trouvée'
  },

  login: {
    login: 'Connexion',
    sigin: 'Accéder',
    email: 'Email',
    password: 'Mot de passe',
    recover: 'Récupérer',
    password_recover: 'Récupérer mot de passe',
    password_recover_success: 'Un mot de passe de récupération a été envoyé à l\'email indiqué',
    password_recover_error: 'Une erreur s\'est produite lors de l\'envoi de l\'email de récupération',
    mandatoryField: 'Champ obligatoire',
    invalidEmail: 'Email non valable',
    noValidRole: 'Utilisateur non valable',
    noValidCredentials: 'Utilisateur ou mot de passe incorrects',
    title: 'hr bot factory est maintenant Velora',
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'éléments',
      labelRowsPerPage: 'Éléments par page',
      firstAriaLabel: 'Début',
      firstTooltip: 'Aller au début',
      previousAriaLabel: 'Précédent',
      previousTooltip: 'Précédent',
      nextAriaLabel: 'Suivant',
      nextTooltip: 'Suivant',
      lastAriaLabel: 'Fin',
      lastTooltip: 'Aller à la fin'
    },
    body: {
      emptyDataSourceMessage: 'Aucune donnée'
    },
    toolbar: {
      searchTooltip: 'Rechercher',
      searchPlaceholder: 'Rechercher'
    }
  },

  savedSearchesItemTableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'éléments',
      labelRowsPerPage: 'Éléments par page',
      firstAriaLabel: 'Début',
      firstTooltip: 'Aller au début',
      previousAriaLabel: 'Précédent',
      previousTooltip: 'Précédent',
      nextAriaLabel: 'Suivant',
      nextTooltip: 'Suivant',
      lastAriaLabel: 'Fin',
      lastTooltip: 'Aller à la fin'
    },
    body: {
      emptyDataSourceMessage: 'Aucune donnée trouvée pour cette recherche sauvegardée'
    },
    toolbar: {
      searchTooltip: 'Rechercher',
      searchPlaceholder: 'Rechercher'
    }
  },

  errors: {
    internalServerError: 'L\'opération n\'a pas pu être réalisée.'
  },

  landingEmail: {
    title: 'Une erreur s\'est produite',
    body: 'Échec de l\'opération',
    loading: 'Chargement',
    hi: "Bonjour",
    citation_header: "Nous souhaitons vous informer qu'après avoir évalué votre profil, nous aimerions avancer avec vous vers la prochaine étape du processus de sélection pour le poste de",
    citation_info: "Informations de l'appel",
    address: "Adresse :",
    contact_person: "Personne de contact :",
    interview_date: "Date de l'entretien :",
    interview_hour: "Heure de l'entretien :",
    give_answer: 'Veuillez nous envoyer votre réponse à cet appel en sélectionnant l\'une des options suivantes :',
    confirmed: 'Je confirme ma participation',
    reschedule: 'Je ne peux pas participer, veuillez me donner un autre rendez-vous',
    not_interested: 'L\'offre ne m\'intéresse pas',
    citation_not_exist: "Il n'existe pas de citation",
  },

  landingReschedule: {
    title: 'Sur quelle plage horaire pouvez-vous participer à un entretien ?',
    noDates: 'Aucune date disponible',
    successSend: 'Votre demande a été envoyée avec succès',
    putAvailability: 'Introduire la disponibilité',
    send: 'Envoyer',
    reasonPlaceholder: 'Introduire la raison'
  },

  landingNotInterested: {
    title: 'Veuillez indiquer les raisons pour lesquelles vous êtes intéressé(e)',
    error: 'Une erreur s\'est produite',
    successSend: 'Votre demande a été envoyée avec succès',
    putReason: 'Introduire la raison',
    send: 'Envoyer'
  },

  peopleTableFilters: {
    toManage: 'Pour gérer',
    inProgress: 'En cours',
    finalists: 'Finalistes',
    others: 'Autres'
  },

  noCvInfo: 'Les candidats sélectionnés n\'ont pas de CV associés',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Facteurs de départ',
      superAgree: 'Tout à fait d\'accord',
      agree: 'D\'accord',
      littleDisagree: 'Un peu d\'accord',
      disagree: 'Pas du tout d\'accord',
      clima1: 'Bonne ambiance de l\'entreprise',
      clima2: 'Bonne ambiance avec votre équipe',
      concilia1: 'Je peux concilier vie de famille et travail',
      concilia2: 'Je peux concilier études et travail',
      desarrollo1: 'Rémunération en conformité avec le poste',
      desarrollo2: 'Évolution professionnelle facilitée'
    },
    exits: 'Départs',
    exit_completed: 'Clos',
    recommends: 'Oui, recommandé',
    reason: 'Raisons du départ'
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Processus de transactions reçus',
    suitables: 'Traités vs total',
    numberOfPolls: 'Enquêtes complétées',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Processuss transactionnels par types',
    processesConsultivoGroup: 'Processus de consultations par types',
    startDate: 'Du',
    endDate: 'Au',
    candidatures: 'Candidatures',
    name: 'Nom',
    processes: 'Processus',
    recived: 'Reçus',
    total: 'Total',
    suitablesVsRecibidos: 'En cours vs total',
    tableTitle: 'Processus transactionnels'
  },
  tableFilters: {
    toManage: 'Pour gérer',
    inProgress: 'En cours',
    finalists: 'Finalistes',
    others: 'Autres'
  },
  onboardingInbox: {
    title: 'Intégration Inbox'
  },
  onboarding: {
    deleteSuccess: 'L\'élément a été éliminé avec succès',
    notesTitle: 'Notes'
  },
  status: {
    received: 'Reçu',
    canceled: 'Annulé',
    in_process: 'En cours',
    pending_info: 'En attente d\'informations',
    processed: 'Traité',
    accepted: 'Accepté',
    rejected: 'Rejeté',
    declined: 'Rejeté',
    none: 'Sans réponse',
    pending: 'Sans réponse',
    tentative: 'Provisoire',
    postponed: 'Reporté',
    developing: 'En cours de développement',
    implemented: 'Implanté',
    1: 'Apte',
    2: 'Inapte',
    3: 'À confirmer',
    4: 'Confirmé',
    5: 'Non intéressé',
    6: 'Reprogrammer',
    7: 'Engagé',
    8: 'Réservation',
    9: 'Ne participe pas',
    10: 'Convoqué phase 2',
    11: 'Rejeté',
    12: 'Rejeté phase 2',
    13: 'Non intéressé phase 2',
    14: 'Ne participe pas phase 2',
    15: 'En cours',
    16: 'Doc requis',
    17: 'Documentation reçue',
    18: 'Actualisation en cours',
    19: 'Convoqué'
  },
  evaluativeConfig: {
    pending: 'En attente',
    in_review: 'En cours de vérification',
    suitable_in_other_process: 'Apte pour d\'autres postes',
    suitable: 'Apte',
    not_suitable: 'Rejeté',
    resignation: 'Démission',
    no_show: 'Ne s\'est pas présenté(e)'
  },
  dialog: {
    success: 'Accepter',
    decline: 'Annuler',
    save: 'Sauvegarder'
  },

  feedbackStatisticsItems: {
    Received: 'Suggestions reçues',
    suitables: 'Suggestions en cours vs total',
    category: 'Suggestions par catégories',
    department: 'Suggestions par zones',
    centerType: 'Suggestions par unité',
    statesTypes: 'Suggestions par statuts',
    endDate: 'Au',
    name: 'Nom',
    recived: 'Reçus',
    total: 'Total'
  },

  onboardingStatisticsItems: {
    leaving: 'Départs',
    hiring: 'Intégrations',
    inprogress: 'Intégrations en cours',
    ratio: 'Ratio',
    phase: 'Phase',
    hot_issues_title: 'Questions actuelles',
    hot_issues: {
      formacion_online: 'Formation en ligne',
      uniforme: 'Uniforme disponible',
      epi: 'EPI disponible',
      contrato: 'Contrat remis',
      rm: 'Visite médicale',
      relacion_equipo: 'Mauvaises relations avec l\'équipe',
      formacion_riesprof_hito3: 'Formation de prévention des risques',
      mutua_mc_mutual_hito3: 'Protocole d\'action en cas d\'accident',
      formacion_compliance_hito3: 'Formation conformité',
      reconocimiento_medico_hito3: 'Visite médicale',
      welcomepack_hito3: 'Pack de bienvenue',
      employeeapp_hito3: 'Appli. employé',
      protocol_bajamed_hito4: 'Protocole arrêt de travail',
      reunion_manager_hito5: 'Réunion retour',
      tarjeta: 'Pointage',
      hardskill1_p4: 'Enregistrement portail des employés',
      normativa: "Accès à la réglementation interne",
      contrato_tem: "Contrat signé",
      funciones: "Fonctions et objectifs",
      manual_emergencia: "Manuel d'urgences"
    }
  },

  offerStates: {
    unanswered: 'Aucune réponse',
    interested: 'Intéressé(e)',
    not_interested: 'Pas intéressé(e)'
  },

  talent_acquisition: {
    inbox: {
      title: 'Candidatures'
    },
    favorites: {
      title: 'Favoris'
    },
    'saved-searches': {
      title: 'Recherches sauvegardées'
    },
    'saved-searches-item': {
      title: 'Recherches sauvegardées'
    },
    'push-offer': {
      title: 'Offres Push'
    },
    'offer-candidatures': {
      title: 'Offres Push'
    },
    'pull-config': {
      title: 'Pull de candidats'
    },
    planning: {
      title: 'Planification'
    },
    reports: {
      title: 'Rapports'
    },
    statistics: {
      title: 'Statistiques'
    },
    'user-manual': {
      title: 'Manual utilisateur'
    },
    'evaluative-tests': {
      title: 'Tests d\'évaluation'
    }
  },

  on_board: {
    inbox: {
      title: ''
    }
  },

  employee_line: {
    inbox: {
      title: ''
    },
    'saved-searches': {
      title: 'Recherches sauvegardées'
    },
    'saved-searches-item': {
      title: 'Recherches sauvegardées'
    }
  },

  feedback: {
    inbox: {
      title: ''
    },
    title: 'Boîte de réception éléments',
    filter: 'Filtrer éléments',
    date: 'Date',
    questions: 'Questions',
    proposal: 'Élément',
    category: 'Catérgorie',
    email: 'Email',
    dni: 'Nº identité DNI',
    phone: 'Téléphone'
  },

  exit_interview: {
    inbox: {
      title: 'Sortir de l\'entretien'
    },
    exit_reasons: 'Raisons de la sortie',
    exit_factors: 'Facteurs de départ',
    other_data: 'Autres données',
    position: 'Poste',
    leaving_date: 'Date de sortie',
    male_or_female: 'Sexe',
    male: 'Masculin',
    female: 'Féminin',
    category: 'Catérgorie',
    email: 'Email',
    dni: 'Nº identité DNI',
    phone: 'Tél',
    Motivo1: 'Raison de la sortie',
    Motivo2: 'Autres raisons de sortie',
    Motivo3: 'Remarques raison de sortie',
    Clima1: 'Les relations avec le responsable ont été',
    Clima2: 'L\'ambiance de travail était satisfaisante',
    Concilia1: 'L\'opportunité de changer d\'équipe vous a-t-elle été proposée ?',
    Concilia2: 'Selon vous, comment pourrions-nous nous améliorer ?',
    Desarrollo1: 'L\'entreprise vous a-t-elle offert des opportunités de développement ?',
    Desarrollo2: 'La formation reçue était-elle conforme à vos attentes ?'
  },

  toast_msg: {
    observation: {
      success: 'Remarque ajoutée avec succès',
      error: 'Une erreur s\'est produite'
    },
    requireDoc: {
      success: 'Demande de documentation envoyée',
      error: 'Erreur de demande de la documentation'
    },
    changeStatus: {
      success: 'Statut modifié avec succès',
      error: 'Une erreur s\'est produite'
    }
  },

  integrations: {
    outlook: 'Mon calendrier Outlook',
    google: 'Mon calendrier Google',
    connect: 'Se connecter',
    disconnect: 'Se déconnecter'
  },

  userManual: {
    title: 'Manual utilisateur'
  },

  superadministratorManual: {
    title: 'Manuel administrateur ATS'
  },

  phases_states: {
    descriptionPhasesStates: 'Vous pouvez créer, modifier ou éliminer ici les phases et les statuts des candidatures.',
    titlePhase: 'Phases',
    titleState: 'Statuts',
    descriptionPhase:
      'Les phases représentent les différentes étapes franchies par les candidats au cours du processus de sélection. Vous pouvez en créer jusqu\'à 8. Las fases predefinidas son: Reçus et engagés.',
    descriptionTwoPhase:
      'En plus de ces phases prédéfinies, l\'application offre la possibilité de créer des phases personnalisées en fonction des besoins spécifiques de votre organisation.',
    descriptionState:
      'Les statuts d\'une phase représentent le niveau de progression d\'un candidat à une étape spécifique du processus de sélection ce qui simplifie également l\'identification et le suivi de la progression du candidat dans cette phase. Los estados predefinidos son: Inscrit et engagé',
    descriptionTwoState:
      'En plus des statuts déjà établis, l\'application vous permet de personnaliser les statuts pour s\'adapter parfaitement aux particularités de votre organisation.',
    labelPhase: 'Phase',
    labelState: 'Statut',
    labelCurrentState: 'Statut actuel',
    labelNewState: 'Statut nouveau',
    errorOnDeleteStatus: 'Vous ne pouvez pas éliminer un statut en cours d\'utilisation, vous avez {candidatures} candidatures que vous devez déplacer auparavant vers un autre statut.',
    placeholderPhase: 'Introduisez le nom de la phase',
    placeholderState: 'Introduisez le nom du statut',
    placeholderSelectPhase: 'Sélectionnez une phase',
    titleButtonPhase: 'Ajouter une phase',
    titleButtonState: 'Ajouter un statut',
    assignStateToPhase: 'Attribuer un statut à une phase',
    buttonSave: 'Sauvegarder',
    buttonAllSave: 'Sauvegarder les changements',
    buttonCancel: 'Annuler',
    buttonMove: 'Déplacer',
    titleFunnel: 'Illustration du flux',
    subtitleFunnel: 'Phases et statuts du processus de sélection',
    titlePhaseFunnel: 'Phases',
    buttonDelete: 'Éliminer',
    selectionFunnel: 'Funnel de sélection',
    currenteState: 'Statut actuel',
    labelColor: 'Attribuer une couleur',
    placeholderDescriptionStatus: 'Introduisez un message',
    feedbackMessage: 'Message de retour',
    feedbackMessageRegistered:
      '<p>Nous vous remercions sincèrement de votre intérêt pour rejoindre notre équipe, et de votre temps et de vos efforts pour présenter votre profil. </p><p><br></p><p>Queremos que sepas que tu candidatura ha sido registrada en nuestro sistema y que estamos revisando cuidadosamente todas las aplicaciones recibidas. </p><p><br></p><p>En estos momentos, estamos evaluando todas las candidaturas y buscando la persona ideal que se ajuste a los requisitos de la posiciÃ³n y a nuestra cultura empresarial. Este proceso puede llevar algÃºn tiempo, ya que estamos comprometidos en realizar una selecciÃ³n minuciosa. </p><p><br></p><p>Te garantizamos que te mantendremos al tanto sobre los siguientes pasos en el proceso de selecciÃ³n tan pronto como tengamos novedades con respecto a una oferta que se alinee con tu perfil y motivaciÃ³n. </p><p><br></p><p>Nuestra intenciÃ³n es ser transparentes y proporcionarte toda la informaciÃ³n necesaria para que puedas seguir el desarrollo de tu candidatura. Muchas gracias por tu paciencia y comprensiÃ³n durante este proceso. </p><p><br></p><p>Nous apprécions votre intérêt de faire partie de notre équipe et nous espérons avoir l\'opportunité de mieux vous connaître lors des étapes suivantes du processus de sélection.</p>',
    feedbackMessageHired:
      '<p>Félicitations ! Nous sommes ravis de t\'informer que tu as été sélectionné pour rejoindre notre équipe. </p><p><br></p><p>Pendant le processus de sélection, tes performances et compétences se sont distinguées, et nous sommes convaincus que ton expérience et tes contributions seront très précieuses pour notre entreprise. </p><p><br></p><p>Dans les prochains jours, nous te contacterons pour te communiquer les détails finaux du contrat, la documentation nécessaire, ainsi que des informations sur l\'orientation et toute préparation nécessaire pour ton premier jour. </p><p><br></p><p>Nous te félicitons encore une fois et attendons le meilleur de toi dans cette nouvelle étape de ta vie professionnelle. </p><p><br></p><p>Bienvenue dans l\'équipe</p>',
    feedbackMessageDefaultCustom: 'Nous étudions votre candidature, et nous vous contacterons bientôt.',
    selectionStatus: 'Sélectionner un statut',
    questionChangeStatus: 'Souhaitez-vous modifier le statut des candidats convoqués ?',
    changeStatusCheckApplication: 'Pour modifier le statut du candidat, sélectionnez le poste et le statut à changer',
    changeStatus: 'Modifier le statut',
    updatedVacancies: 'Postes à pourvoir mis à jour',
    modalEditPhaseTitle: 'Modifier phase',
    modalEditStateTitle: 'Modifier statut',
    modalDeletePhaseTitle: 'Éliminer phase',
    modalDeleteStateTitle: 'Éliminer statut',
    chipUnAssigned: 'Non attribué',
    errorOrderPhasesUpdate: 'Erreur de mise à jour de l\'ordre des phases',
    errorOrderStatusUpdate: 'Erreur de mise à jour de l\'ordre des statuts',
    changesSavedSuccessfully: 'Modifications sauvegardées avec succès',
    phaseReceived: 'Reçus',
    phaseHired: 'Engagés',
    alertCannotAddMoreEightPhases: 'Impossible d\'ajouter plus de 12 phases',
    alertCreatedPhase: 'Phase créée avec succès',
    alertPhaseExists: 'Une phase existe déjà avec ce nom',
    alertErrorCreatePhase: 'Erreur de création de la phase',
    alertAddNamePhase: 'Vous devez ajouter un nom à phase',
    alertCreateStatus: 'Statut créé avec succès',
    alertErrorGetPhases: 'Erreur d\'obtention des phases',
    alertStatusExists: 'Un statut existe déjà avec ce nom',
    alertErrorCreateStatus: 'Erreur de création du statut',
    alertErrorGetStatus: 'Erreur d\'obtention des statuts',
    alertSelectPhase: 'Sélectionnez une phase avant d\'ajouter un statut',
    alertTitleCannotDeletePhase: 'Vous ne pouvez pas éliminer cette phase',
    messageAlertCannotDeletePhase: 'Des statuts sont associés à cette phase. Vous devez les déplacer vers une autre phase existant ou les effacer pour éliminer cette phase.',
    alertThereAreNoStatusesInPhase: 'Aucun statut n\'est associé à cette phase',
    stateInscribed: 'Inscrit',
    stateHired: 'Engagé',
    updatedPhase: 'Phase mise à jour avec succès',
    errorUpdatedPhase: 'Erreur de mise à jour de la phase',
    updatedStatus: 'Statut mis à jour avec succès',
    assignedSuccessfully: 'Processus attribué avec succès',
    errorUpdatedStatus: 'Erreur de mise à jour du statut',
    alertMessageFeedback: 'Vous devez ajouter un message de retour',
    alertStateNameRequired: 'Vous devez ajouter un nom au statut',
    questionNotified: 'Souhaitez-vous envoyer un message informant du changement dans la candidature aux personnes qui passent à ce statut?',
    selectNotificationMethods: 'Choisissez le mode d\'envoi des notifications que vous souhaitez utiliser.',
    infoSelectNotificationMethods: 'N\'oubliez pas d\'utiliser le modèle prévu pour cette communication.',
    smsMethod: 'SMS',
    emailMethod: 'Email',
    whatsapMethod: 'WhatsApp'
  },

  evaluativeTests: {
    title: 'Entretiens vidéos',
    descriptionSection: 'Vous trouverez ici tous vos entretiens vidéos actifs et inactifs.',
    titleNewTest: 'Nouvel entretien vidéo',
    titleEditTest: 'Modifier l\'entretien vidéo',
    subtitleNewTest: 'Configuration de l\'entretien vidéo',
    descriptionNewTest: 'Effectuez la configuration en fonction des besoins du processus de sélection',
    labelPickerStartDate: 'Date de début',
    labelPickerEndDate: 'Date de fin',
    nameTest: 'Nom de l\'entretien vidéo',
    placeholderNameTest: 'Introduisez le nom de l\'entretien vidéo',
    urlBot: 'URL du bot',
    placeholderUrl: 'https://...',
    buttonGenerateUrl: 'Générer URL',
    selectionQuestions: 'Questions de sélection',
    subtitleTest: 'Appliquez un filtre plus complet aux candidats.',
    subtitleTest2: 'Créez au moins une question, attribuez un temps maximum de réponse et concentrez-vous sur les candidats qui ont répondu correctement.',
    placeholderInputQuestion: 'Question nº 1',
    buttonAddQuestion: 'Ajouter une question',
    buttonSave: 'Sauvegarder',
    buttonCancel: 'Annuler',
    buttonRegenerateUrl: 'Générer un nouvel URL',
    inactive: '(désactivée)',
    noResults: 'Aucun résultat trouvé pour la date sélectionnée.',
    errorFetchTests: 'Une erreur s\'est produite lors de l\'accès aux entretiens vidéos ',
    buttonCreateTest: 'Créer entretien vidéo',
    singleQuestion: 'Question',
    multipleQuestions: 'Questions',
    successDeleteTest: 'Entretien vidéo éliminé avec succès',
    successDuplicateTest: 'Entretien vidéo dupliqué avec succès',
    successCreateTest: 'Entretien vidéo créé avec succès',
    successUpdateTest: 'Entretien vidéo mis à jour avec succès',
    useSampleQuestion: 'Utiliser la question en exemple',
    activesTest: 'Affichage actives en cours',
    inactivesTest: 'Affichage toutes',
    placeholderInputTerm: 'Filtrer par nom',
    titleBoxProfileInbox: 'Entretiens vidéos',
    statusVideoInterview: 'Statut de l\'entretien vidéo',
    infoUploadVideo: 'Le candidat n\'a pas téléchargé de vidéo',
    titleTemplate: 'Modèles de bot',
    successChangeStatus: 'Statut modifié avec succès',
    errorFieldRequired: 'Ce champ est obligatoire',
    errorFieldInvalid: 'Champ non valable',
    errorFieldQuestion: 'Vous devez ajouter au moins une question',
    placeholderSelectTemplate: 'Sélectionnez un modèle',
    placeholderTimeLimit: 'Temps maxi.',
    successRegenerateUrl: 'Un nouvel url a déjà été généré et le candidat peut accéder de nouveau',
    errorRegenerateUrl: 'Une erreur s\'est produite lors de la génération d\'un nouvel url',
    inactiveCard: 'Inactive',
    menuEdit: 'Modifier',
    menuAddCsv: 'Importer candidats',
    menuDuplicate: 'Dupliquer',
    menuDelete: 'Éliminer',
    placeholderQuestionOne: 'Décrivez en 30 secondes les éléments principaux de votre expérience professionnelle et leur lien avec le poste de travail pour lequel vous postulez.',
    placeholderQuestionTwo: 'Comment communiquez-vous efficacement avec les différentes parties prenantes (clients, collègues de travail, fournisseurs, etc.) ?',
    placeholderQuestionThree: 'Selon vous, quelles compétences sont les plus importantes pour ce poste ?',
    placeholderQuestionFour: 'Comment contribuez-vous au travail en équipe et à la collaboration dans un environnement professionnel ?',
    placeholderQuestionFive: 'Décrivez une situation dans laquelle vous avez relevé un défi professionnel',
    placeholderQuestionSix: 'Quels sont objectifs à moyen et long terme et dans quelle mesure ce poste s\'aligne-t-il avec ces objectifs ?',
    titleQuestion: 'Question',
    csvUpload: {
      editCta: 'Importer candidats',
      modalCheckCandidates: '%ok% candidats vont être importés dans le fichier.',
      modalImportCandidates: 'Souhaitez-vous continuer ? Ils recevront une notification par Whatsapp/email.',
      candidatesImported: 'Candidats importés avec succès (%ok% / %total%)',
      candidatesNotifiedError: 'Erreur de notification des candidats'
    }
  },

  inboxColumns: {
    Favoritos: 'Favoris',
    'Nombre y apellidos': 'Prénom et nom',
    País: 'Pays ',
    DNI: 'Nº identité DNI',
    'Última videoentrevista': 'Dernier entretien vidéo',
    'Estado videoentrevista': 'Statut de l\'entretien vidéo',
    'Fecha de envío': 'Date d\'envoi'
  },

  filterFields: {
    Nombre: 'Nom',
    DNI: 'Nº identité DNI',
    Email: 'Email',
    'Date de l\'entretien': 'Date de l\'entretien',
    Teléfono: 'Téléphone',
    'Entretien vidéo': 'Entretien vidéo',
    Estado: 'Statut'
  },

  filterBlocks: {
    'Entretien vidéo': 'Entretien vidéo',
    'Données personnelles': 'Données personnelles',
    Evaluación: 'Évaluation'
  },

  user_management: {
    errors: {
      max_users_reached:
        'Vous êtes arrivé au nombre maximum d\'utilisateurs actifs. Si vous souhaitez augmenter votre limite, contactez notre équipe Customer Success.'
    },
    titleSection: 'Utilisateurs',
    searchLabel: 'Recherche',
    filterProfile: 'Filtrer par profil',
    placeholderFilter: 'Sélectionnez n profil',
    descriptionSection:
      'Vous contrôlerez et personnaliserez ici les autorisations et les accès aux administrateurs de la plateforme. Ajoutez, modifiez ou éliminez les comptes utilisateurs, attribuez des rôles spécifiques et assurez-vous d\'une gestion efficace du système.',
    activeUsers: 'Actifs',
    inactiveUsers: 'Inactifs',
    createUser: 'Créez un utilisateur',
    username: 'Nom',
    lastname: 'Nom',
    email: 'Email',
    profile: 'Profil',
    phone: 'Téléphone',
    modalTitleCreate: 'Créez un utilisateur',
    modalTitleUpdate: 'Modifier utilisateur',
    labelShowDefaultFiltersTalent: 'Configurer filtres par défaut du module de talent',
    labelShowDefaultFilters: 'Configurer filtres par défaut du module de %module%',
    labelName: 'Nom',
    labelLastName: 'Nom',
    labelEmail: 'Email',
    labelModules: 'Modules',
    labelProfile: 'Profil',
    labelPassword: 'Mot de passe provisoire',
    buttonCreateUser: 'Créez un utilisateur',
    buttonCancel: 'Annuler',
    buttonUpdateUser: 'Mettre à jour l\'utilisateur',
    placeholderName: 'Saisissez le prénom',
    placeholderLastName: 'Saisissez le nom',
    placeholderEmail: 'Saisissez l\'email',
    placeholderPassword: 'Saisissez le mot de passe',
    placeholderProfile: 'Sélectionnez n profil',
    disableUsers: 'Désactiver utilisateurs',
    disableUser: 'Désactiver utilisateur',
    enableUsers: 'Activer utilisateurs',
    enableUser: 'Activer utilisateur',
    inputEmailUser: 'Introduisez l\'email de l\'utilisateur',
    confirmDeactivation: 'Veuillez confirmer que vous souhaitez désactiver l\'utilisateur {name} {surname}?',
    confirmActivation: 'Veuillez confirmer que vous souhaitez activer l\'utilisateur {name} {surname}?',
    buttonConfirmDisable: 'Oui, désactiver utilisateur',
    buttonConfirmEnable: 'Oui, activer utilisateur',
    buttonConfirmDisableUsers: 'Oui, désactiver utilisateurs',
    buttonConfirmEnableUsers: 'Oui, activer utilisateurs',
    atsAdminUser: 'Super administrateur',
    supervisorUser: 'Superviseur',
    basicUser: 'Basique',
    customUser: 'Personnalisé',
    readOnlyUser: 'Lecture seule',
    createdUser: 'Utilisateur créé avec succès',
    editedUser: 'Utilisateur modifié avec succès',
    errorCreateUser: 'Erreur de création de l\'utilisateur',
    errorEditUser: 'Erreur de modification de l\'utilisateur',
    nameRequired: 'Vous devez ajouter un prénom',
    surnameRequired: 'Vous devez ajouter un nom',
    emailRequired: 'Vous devez ajouter un email',
    emailInvalid: 'Email non valable',
    passwordRequired: 'Vous devez ajouter un mot de passe',
    moduleRequired: 'Vous devez sélectionner un module',
    profileRequired: 'Vous devez sélectionner un profil',
    confirmDisableUsers: 'Veuillez confirmer que vous souhaitez désactiver les utilisateurs suivants',
    confirmEnableUsers: 'Veuillez confirmer que vous souhaitez activer les utilisateurs suivants',
    userDisabledSuccessfully: 'Utilisateur désactivé avec succès',
    usersDisabledSuccessfully: 'Utilisateurs désactivés avec succès',
    userEnabledSuccessfully: 'Utilisateur activé avec succès',
    usersEnabledSuccessfully: 'Utilisateurs activés avec succès',
    userDisabledError: 'Erreur de désactivation de l\'utilisateur',
    usersDisabledError: 'Erreur de désactivation des utilisateurs',
    userEnabledError: 'Erreur d\'activation de l\'utilisateur',
    usersEnabledError: 'Erreur d\'activation des utilisateurs',
    passwordRequirements: "Le mot de passe doit contenir au moins 12 caractères et inclure une combinaison de majuscules, minuscules, chiffres et caractères spéciaux.",
    passwordInvalid: 'Le mot de passe est non valable',
    passwordValid: 'Le mot de passe est valable',
    userAlreadyExists: 'L\'utilisateur existe déjà !',
    dateCreated: 'Date de création',
    labelAuthorizedSections: 'Sections',
    placeholderModules: 'Sélectionnez les modules pour cet utilisateur',
    placeholderAuthorizedSections: 'Sélectionnez les sections pour ce profil',
    authorizedSectionsRequired: 'Vous devez donner l\'accès à des sections',
    inbox: 'Inbox',
    push_offer: 'Offres push',
    pull_config: 'Configuration pull',
    saved_searches: 'Recherches sauvegardées',
    planning: 'Planification',
    favorites: 'Favoris',
    reports: 'Rapports',
    statistics: 'Statistiques',
    statistics_dashboard: 'Tableau de bord statistiques',
    rpa_config: 'Origines de recrutement',
    evaluative_tests: 'Entretiens vidéos',
    template_requestor: 'Gestionnaire de demande de modèle',
    template_validator: 'Validation des processus',
    module: 'Module'
  },

  ia_evaluation: {
    titleSection: 'Évaluation automatisée par IAG',
    descriptionSection:
      'Personnalisez et configurez le système de sélection automatisé par IAG. Lors de cette première étape, créez et modifiez vos évaluations en fonction des postes configurés.',
    createEvaluation: 'Créer une nouvelle évaluation',
    searchLabel: 'Recherche',
    placeholderInputTerm: 'Nom',
    active: 'Actifs',
    inactive: 'Inactifs',
    disable: 'Désactiver évaluation',
    enable: 'Activer évaluation',
    name: 'Nom',
    job: 'Poste',
    family: 'Famille',
    criteria: {
      experience: 'Expérience',
      education_training: 'Formation',
      salary_expectation: 'Perspectives sal.',
      employment_status: 'Situation professionnelle',
      location: 'Lieu',
      mobility: 'Mobilité',
      availability: 'Disponibilité'
    },
    relevance_level: {
      relevant: 'Certain',
      desired: 'Souhaitable',
      mandatory: 'Écarté',
      ignore: 'Ne pas évaluer',
      others: 'Informative',
    },
    buttonConfirmDisable: 'Oui, désactiver évaluation',
    buttonConfirmEnable: 'Oui, activer évaluation',
    confirmDeactivation: 'Veuillez confirmer que vous souhaitez désactiver l\'évaluation {name}',
    confirmActivation: 'Veuillez confirmer que vous souhaitez activer l\'évaluation {name}',
    evaluationDisabledSuccessfully: 'Évaluation désactivée avec succès',
    evaluationEnabledSuccessfully: 'Évaluation activée avec succès',
    evaluationDisabledError: 'Erreur de désactivation de l\'évaluation',
    evaluationEnabledError: 'Erreur d\'activation de l\'évaluation',
    wizard: {
      title_create: 'Créer une nouvelle évaluation',
      title_edit: 'Modifier évaluation',
      subtitle: 'Critères d\'évaluation',
      description:
        'Les critères d\'évaluation peuvent inclure des aspects comme l\'expérience, la formation, l\'emplacement, entre autres. Cette sélection établit l\'évaluation initiale des candidats et constitue une étape essentielle pour garantir un processus de sélection aligné avec les caractéristiques spécifiques de l\'évaluation en cours de configuration.',
      name: 'Nom',
      name_placeholder: 'Écrivez le nom du profil',
      family: 'Famille de postes',
      family_placeholder: 'Sélectionnez une famille de postes',
      job: 'Postes',
      job_placeholder: 'Sélectionnez un poste',
      rpa_credentials: 'Référence de l\'origine du recrutement',
      rpa_credentials_placeholder: 'Sélectionnez une origine du recrutement',
      info_text: 'Vous pouvez définir des réglages pour l\'évaluation du poste de:',
      info_text_values: '<strong>{job}</strong> de la famille <strong>{family}</strong>.',
      tableHeader: 'Choisissez les éléments auxquels vous souhaitez attribuer plus de poids dans l\'évaluation:',
      save: 'Sauvegarder les réglages',
      cancel: 'Annuler',
      save_confirmation_title: 'Confirmez-vous le {mode} d\'évaluation ?',
      save_confirmation_button: 'Je confirme {mode}',
      create_mode: 'création',
      edit_mode: 'modification',
      successCreateEvaluation: 'Évaluation créée avec succès',
      errorCreateEvaluation: 'Erreur de création de l\'évaluation',
      successUpdateEvaluation: 'Évaluation mise à jour avec succès',
      errorUpdateEvaluation: 'Erreur de mise à jour de l\'évaluation'
    }
  },

  job_offers: {
    titleSection: 'Postes',
    descriptionSection:
      'Que voyez-vous ici ? Dans cette vue, vous trouverez la liste de vos offres publiées dans les sources de recrutement configurées, ainsi que les candidatures spontanées et les processus que vous créez manuellement. Accédez à chacun d\'entre eux pour visualiser les candidats et gérer vos processus de sélection.',
    filterJobs: 'Filtrer postes',
    createJob: 'Créer poste',
    name: 'Poste',
    createdBy: 'Créé par',
    keyword: 'Mot clé',
    candidature_name: 'Poste',
    not_available: 'Non disponible',
    profile: 'Profil évaluation',
    location: 'Emplacement',
    createdAt: 'Date création',
    endDate: 'Date de fin',
    recruitmentSource: 'Origine recrutement',
    newCandidates: 'Candidats nouveaux',
    totalCandidates: 'Candidats en tout',
    candidatesNewVsTotal: 'Candidats\n nouveaux / en tout',
    infojobs: 'Infojobs',
    infojobsPriority: 'Infojobs',
    epreselec: 'ePreselec',
    epreselecPriority: 'ePreselec',
    pandape: 'Pandapé',
    pandapePriority: 'Pandapé',
    turijobs: 'Turijobs',
    turijobsPriority: 'Turijobs',
    computrabajo: 'Computrabajo',
    computrabajoPriority: 'Computrabajo',
    talentclue: 'Talent Clue',
    bizneo: 'Bizneo',
    indeed: 'Indeed',
    manual: 'Manuelle',
    spontaneous: 'Spontanée',
    active_offers: 'postes actifs',
    inactive_offers: 'postes inactifs',
    filter_offers: 'Filtrer postes',
    offer_status: 'Statut du poste',
    active_label: 'Actifs',
    inactive_label: 'Inactifs',
    wizard: {
      title_create: 'Créer un nouveau poste',
      title_edit: 'Modifier poste',
      subtitle: 'Critères d\'évaluation',
      description:
        'Dans ce mode vous pourrez créer un nouveau poste, lui donner un nom, attribuer un emplacement, et les détails que vous considérez opportuns. Après l\'avoir créé, vous pourrez attribuer des candidats de Inbox à ce poste et les regrouper ainsi dans un même processus et gérer chaque candidature à ce poste.',
      name: 'Nom',
      name_placeholder: 'Introduisez le nom du poste',
      description_value: 'Description',
      description_value_placeholder: 'Introduisez une description',
      evaluation_profile: 'Workflow d\'évaluation',
      evaluation_profile_placeholder: 'Sélectrionnez un workflow d\'évaluation',
      location: 'Emplacement',
      location_placeholder: 'Introduisez l\'emplacement',
      recruitment_source: 'Origine de recrutement',
      recruitment_source_placeholder: 'Sélectionnez une origine du recrutement',
      created_at: 'Date de création',
      created_at_placeholder: 'Sélectrionnez la date de création',
      end_at: 'Date de fin',
      end_at_placeholder: 'Sélectrionnez la date de fin',
      save: 'Sauvegarder',
      save_confirmation_title: 'Confirmez-vous le {mode} du poste ?',
      save_confirmation_button: 'Je confirme {mode}',
      create_mode: 'création',
      edit_mode: 'modification',
      successCreateJobOffer: 'Poste créé avec succès',
      errorCreateJobOffer: 'Erreur de création du poste',
      successUpdateJobOffer: 'Poste mis à jour avec succès',
      errorUpdateJobOffer: 'Erreur de mise à jour du poste'
    }
  },
  job_offer_applications: {
    backToJobOffers: 'Retour aux postes',
    filter: 'Filtrer candidats',
    is_favorite: 'Favoris',
    name_and_surname: 'Prénom et nom',
    rating: 'Rating Candidat',
    level_one: 'Ponctuation niveau 1',
    level_two: 'Ponctuation niveau 2',
    level_three: 'Puntuación Nivel 3',
    location: 'Lieu',
    phase: 'Phase',
    selection_status: 'Statut',
    created_at: 'Date',
    last_job: 'Dernier poste',
    match: 'Match',
    bot_matching_pending: 'En attente',
    formation: 'Formation',
    cv: 'CV'
  },
  tag_management: {
    titleSection: 'Etiquettes',
    searchLabel: 'Recherche',
    filterModule: 'Filtrer par module',
    placeholderFilter: 'Sélectionnez un module',
    descriptionSection: 'Vous contrôlerez et personnaliserez ici les étiquettes. Ajoutez, modifiez ou éliminez des étiquettes pour un flux de travail efficient.',
    createTag: 'Créer étiquette',
    name: 'Nom',
    description: 'Description',
    color: 'Couleur',
    module: 'Module',
    modalTitleCreate: 'Créer étiquette',
    modalTitleUpdate: 'Modifier étiquette',
    labelName: 'Nom',
    labelDescription: 'Description',
    labelColor: 'Couleur',
    labelModule: 'Module',
    labelMatchingMin: 'Ponctuation minimale',
    labelMatchingMax: 'Ponctuation maximale',
    buttonCreateTag: 'Créer étiquette',
    buttonCancel: 'Annuler',
    buttonUpdateTag: 'Mettre à jour étiquette',
    placeholderName: 'Saisissez le prénom',
    placeholderDescription: 'Introduisez la description',
    placeholderColor: 'Introduisez la couleur',
    placeholderModule: 'Sélectionnez un module',
    placeholderMatchingMin: 'Sélectionnez la ponctuation minimum de coïncidence pour l\'auto-attribution',
    placeholderMatchingMax: 'Sélectionnez la ponctuation maximum de coïncidence pour l\'auto-attribution',
    nameRequired: 'Vous devez ajouter un prénom',
    descriptionRequired: 'Vous devez ajouter une description',
    colorRequired: 'Vous devez ajouter une couleur',
    moduleRequired: 'Vous devez sélectionner un module',
    dateUpdated: 'Date de mise à jour',
    errorCreateTag: 'Erreur de création de l\'étiquette',
    errorUpdateTag: 'Erreur de mise à jour de l\'étiquette',
    deleteTagError: 'Erreur d\'élimination de l\'étiquette',
    createdTag: 'Étiquette créée avec succès',
    editedTag: 'Étiquette modifiée avec succès',
    removeTag: 'Éliminer étiquette',
    remove_tag_confirmation: 'Veuillez confirmer que vous souhaitez éliminer l\'étiquette {tag_name}',
    remove_confirmed: 'Oui, éliminer étiquette',
    deleteTagSuccess: 'Étiquette éliminée avec succès',
    tag_in_use:
      'Cette étiquette est actuellement attribuée à un ou plusieurs candidats. Tienes dos opciones: Borrar directamente la etiqueta quedando estos candidatos sin etiquetar, o asignarles otra de las disponibles. Que souhaitez-vous faire ?',
    remove_anyways: 'Éliminer de toutes façons',
    labelCurrentTag: 'Étiquette actuelle',
    labelNewTag: 'Nouvelle étiquette',
    moveToEmpty: 'Vous devez sélectionner vers quelle étiquette le déplacer.'
  },

  keywords_rpa: {
    titleSection: 'Jobsites',
    descriptionSection:
      'Dans cette section, vous pourrez configurer et personnaliser les intégrations des origines du recrutement, ajouter le fournisseur, les références et surtout générer les mots clés de suivi.',
    newIntegration: 'Nouvelle intégration',
    searchLabel: 'Recherche',
    filterSource: 'Filtraer par origine',
    placeholderFilter: 'Sélectionnez une origine',
    sourceInfojobs: 'Infojobs',
    sourceEpreselec: 'ePreselec',
    sourceComputrabajo: 'Computrabajo',
    sourcePandape: 'Pandapé',
    sourceTurijobs: 'Turijobs',
    activeIntegrations: 'Actives',
    inactiveIntegrations: 'Inactives',
    inactiveIntegration: 'Intégration inactive',
    position: 'Poste',
    source: 'Origine de recrutement',
    user: 'Utilisateur',
    keyword: 'Mot clé',
    lastExecution: 'Dernière exécution',
    connectionStatus: 'Statut de connexion',
    connectionStatuses: {
      pending: 'En cours d\'exécution',
      ok: 'Connexion établie',
      error_login: 'Références non valables',
      error_generic: 'Erreur'
    },
    labelPosition: 'Poste',
    labelSource: 'Origine de recrutement',
    labelAdvancedConfig: 'Configuration avancée',
    labelConcatUrl: 'Paramètres de l\'url',
    labelUser: 'Utilisateur',
    labelPassword: 'Mot de passe',
    labelUrl: 'URL',
    placeholderPosition: 'Sélectionnez le poste',
    placeholderSource: 'Sélectionnez une origine',
    placeholderConcatUrl: 'Introduisez les paramètres de l\'url',
    placeholderUser: 'Introduisez l\'utilisateur',
    placeholderPassword: 'Saisissez le mot de passe',
    placeholderUrl: 'Nom de client',
    placeholderKeyword: 'Mot de passe généré',
    buttonGenerateKeyword: 'Générer mot de passe',
    modalTitleNewIntegration: 'Nouvelle intégration',
    modalTitleEditIntegration: 'Modifier intégration',
    buttonCancel: 'Annuler',
    buttonSaveIntegration: 'Sauvegarder intégration',
    buttonUpdateIntegration: 'Mettre à jour intégration',
    messageGenerateKeyword:
      'À présent, pour terminer l\'intégration, nous devons attribuer un mot clé que vous devrez inclure à toutes les offres que vous publiez pour cette origine du recrutement et pour ce poste. Veuillez cliquer sur le bouton pour recevoir ce mot clé',
    positionRequired: 'Vous devez sélectionner un poste',
    sourceRequired: 'Vous devez sélectionner une origine',
    usertRequired: 'Vous devez ajouter un utilisateur',
    passwordRequired: 'Vous devez ajouter un mot de passe',
    urlRequired: 'Vous devez ajouter une URL',
    generatedKeywordRequired: 'Vous devez générer le mot clé pour pouvoir continuer',
    messageConfirmData: 'Voici les données de l\'intégration que vous venez d\'enregistrer. Sont-elles correctes ?',
    messageConfirmDataEdit: 'Voici les données de l\'intégration que vous venez de modifier. Sont-elles correctes ?',
    buttonConfirmIntegration: 'Oui, sauvegarder intégration',
    buttonCancelIntegration: 'Non, annuler',
    recoverPasswordEmail: 'Récupérer mot de passe',
    buttonConfirmDisabledIntegration: 'Oui, désactiver intégration',
    buttonConfirmEnabledIntegration: 'Oui, activer intégration',
    confirmDisabledIntegration: 'Veuillez confirmer que vous souhaitez désactiver l\'intégration {position} {provider}',
    confirmEnabledIntegration: 'Veuillez confirmer que vous souhaitez activer l\'intégration {position} {provider}',
    successCreateIntegration: 'Intégration créée avec succès',
    errorCreateIntegration: 'Erreur de création de l\'intégration',
    errorUpdateIntegration: 'Erreur de mise à jour de l\'intégration',
    successUpdateIntegration: 'Intégration mise à jour avec succès',
    integrationAlreadyExists: 'L\'intégration existe déjà !',
    successSendPasswordEmail: 'Mot de passe envoyé avec succès',
    errorSendPasswordEmail: 'Erreur d\'envoi du mot de passe',
    titlePosition: 'Poste:',
    titleSource: 'Origine de recrutement:',
    titleConcatUrl: 'Paramètres de l\'url:',
    titleUser: 'Utilisateur:',
    titleKeyword: 'Mot clé:',
    integrationDisabledSuccessfully: 'Intégration désactivée avec succès',
    integrationEnabledSuccessfully: 'Intégration activée avec succès',
    urlRequirements: "L'URL doit avoir le format : https://cliente.admin.epreselec.com/es/vacantes, remplacer 'client' par le nom de votre client",
    invalidUrl: 'URL non valable',
    emailLabel: 'Email',
    passwordLabel: 'Mot de passe',
    verifyPasswordUserPlatformToEditeIntegration: 'Pour modifier l\'intégration, veuillez vérifier le mot de passe de l\'utilisateur de la plateforme.',
    verifyButton: 'Vérifier',
    errorPasswordIncorrect: 'Mot de passe incorrect',
    successPasswordVerified: 'Mot de passe vérifié avec succès',
    prefixUrl: 'https://',
    sufixUrl: '.admin.epreselec.com/es/vacantes',
    sourceInfojobsPriority: 'Infojobs',
    sourceEpreselecPriority: 'ePreselec',
    sourceComputrabajoPriority: 'Computrabajo',
    sourcePandapePriority: 'Pandapé',
    sourceTurijobsPriority: 'Turijobs'
  },

  dashboard: {
    filters: {
      division: 'Division',
      area: 'Secteur',
      subarea: 'Sous-division',
      center: 'Centre',
      candidature: 'Poste'
    },
    no_data_available: 'Aucunes données disponibles pour la plage sélectrionnée',
    home: {
      title: 'Panels de statistiques',
      create: 'Créer nouveau panel',
      editDashboard: 'Modifier panel',
      saveDashboard: 'Sauvegarder panel',
      removeDashboard: 'Éliminer panel',
      removeDashboardSuccess: 'Le panel a été éliminé avec succès',
      removeDashboardError: 'Erreur d\'élimination du panel',
      changes_not_saved: 'Vous avez des changements dans le panel. Sauvegarder pour ne pas les perdre',
      successfully_saved: 'Modifications sauvegardées avec succès',
      error_saving: 'Erreur de sauvegarde des changements',
      successfully_deleted: 'Widget éliminé avec succès',
      error_deleting: 'Erreur d\'élimination du widget',
      deleteWidgetDialogTitle: 'Souhaitez-vous vraiment éliminer ce widget ?',
      deleteCancel: 'Annuler',
      deleteConfirm: 'Confirmer',
      no_dashboards_created:
        'Aucun tableau de bord de statistiques créé sur votre compte. Une fois que votre administrateur aura créé les tableaux de bord, vous pourrez les voir dnas cette section.'
    },
    weekdays: {
      '2': 'Lundi',
      '3': 'Mardi',
      '4': 'Mercredi',
      '5': 'Jeudi',
      '6': 'Vendredi',
      '7': 'Samedi',
      '1': 'Dimanche'
    },
    pie: {
      accepted: 'Acceptées',
      not_accepted: 'Non acceptées',
      rejected: 'Rejetés',
      pending: 'En attente',
      replan: 'Nouvelle planification',
      tentative: 'Provisoire',
      total: 'Total',
      suitable: 'Aptes',
      not_suitable: 'Inaptes',

      gpp_manual: 'Manuelle',
      gpp_auto: 'Automatique',
      gpp_gap: 'GAP',
      gpp_replacement: 'Substitution',
      gpp_pending: 'En attente',
      gpp_cancelled: 'Annulés',
      gpp_rejected: 'Rejetés',
      gpp_active: 'En cours',
      gpp_empty: 'Vides',
      gpp_inactive: 'Expirés',
      gpp_closed: 'Clos',
      gender_male: 'Hommes',
      gender_female: 'Femmes',
      gender_undefined: 'Indéfini'
    },
    barchart: {
      hour_of_day: ':00h',
      interviews: ' entretiens'
    },
    funnel: {
      applied: 'Inscrits',
      sent: 'Envoyés',
      loaded: 'Ouverts',
      interviewed: 'Interviewés',
      gpp_total: 'Créés',
      gpp_pending: 'En cours',
      gpp_closed: 'Clos'
    },
    whatsapp: {
      bot_starter: 'Invitations entretien',
      citations: 'Convocations',
      offers: 'Offres push',
      pulls: 'Pull de candidats'
    },
    features: {
      push_offer: 'Offres push',
      doc_request: 'Demande de documentation',
      video_analysis: 'Analyse vidéo',
      pull_config: 'Pull des candidats',
      edit_fields: 'Modification de champs',
      phases_status: 'Phases et statuts',
      evaluative_tests: 'Tests d\'évaluation',
      vacancy_request: 'Gestion de demandes de modèle (GPP)',
      job_offer: 'Postes'
    },
    tooltip: {
      ats_access_count: 'La session utilisateur a une durée d’un jour',
      candidate_experience_cnps: 'Le CNPS est calculé en fonction des résultats de l\'enquête de satisfaction des candidats',
      average_time_to_hire:
        'Établit la moyenne de temps en jours (:value:) pour engager un candidat, en indiquant également la valeur minimum et maximum (:max:) des jours',
      gpp_average_time_to_hire:
        'Établit la moyenne de temps en jours (:value:) pour clore un processus, en indiquant également la valeur minimum et maximum (:max:) des jours'
    },
    edit: {
      title: 'Modification du panel:',
      save: 'Sauvegarder panel',
      saving: 'Sauvegarde en cours...',
      saved: 'Panel sauvegardé avec succès',
      renamed: 'Panel mis à jour avec succès',
      removed: 'Panel éliminé avec succès',
      disable: 'Éliminer panel',
      viewDashboard: 'Visualiser panel',
      renameDashboard: 'Renommer panel',
      renameConfirm: 'Sauvegarder',
      removeDialogTitle: 'Souhaitez-vous vraiment éliminer ce panel ?',
      removeConfirm: 'Confirmer',
      cancel: 'Annuler',
      errorRemoving: 'Erreur d\'élimination du panel',
      errorRenaming: 'Erreur pour renommer le panel',
      placeholderRename: 'Nom du panel',
      findWidgets: 'Rechercher widgets',
      addWidgets: 'Sélectionnez les widgets pour votre panel',
      alertHasUnsavedChanges: 'Panel modifié : n\'oubliez pas de le sauvegarder pour ne pas perdre vos changements'
    },
    wizard: {
      title: 'Panels créés par',
      new_from_scratch: 'Créer panel personnalisé',
      placeholder_custom_dashboard: 'Écrivez le nom de votre panel',
      create: 'Créer',
      viewDashboards: 'Retour',
      error_name: 'Introduisez un nom pour le nouveau panel',
      error_creating: 'Une erreur s\'est produite lors de la création du panel',
      copy_select_preconfigured_dashboard: 'Choisissez l\'un des panels de statistiques conçus dans hr bot factory:',
      copy_select_custom_dashboard: 'Ou si vous le préférez, créez un panel personnalisé avec les widgets qui vous intéressent le plus:',
      name: {
        virtual_evaluation_asistant: 'Évaluation participants virtuels',
        selection_pipeline: 'Pipeline de sélection',
        recruitment_source: 'Origines du recrutement',
        candidates_comunication: 'Communication aux candidats',
        hired_services: 'Services engagés',
        conversion: 'Conversion',
        citation: 'Convocation',
        user_activities: 'Activité des utilisateurs',
        multiposting: 'Multiposting',
      },
      description: {
        virtual_evaluation_asistant: 'Vision détaillée de plusieurs facteurs et statistiques clés en lien avec l\'interaction et l\'efficacité',
        selection_pipeline: 'Représentation visuelle de toutes les étapes du processus de recrutement',
        recruitment_source:
          'Tableau de bord qui fournit une vision détaillée des diverses origines de recrutement utilisées, des portails de recrutement aux réseaux sociaux et aux références internes',
        candidates_comunication: 'Offre une vision complète de toutes les interactions et communications avec les candidats',
        hired_services: 'Information sur les services engagés et les fonctionnalités actives',
        conversion: 'Détails des conversions et enregistrements des candidats dans ATS',
        citation: 'Vision des convocations et pourcentages de réponse et participation des candidats',
        user_activities: 'Facteurs internes sur les statistiques et activité des utilisateurs dans ATS',
        multiposting: 'Métriques sur les statistiques de la fonctionnalité de Multiposting',
      }
    },
    widget_groups: {
      status_phases: 'Pipeline de sélection',
      virtual_assistant_evaluation: 'Évaluation participants virtuels',
      recruitment_sources: 'Origines de recrutement',
      candidates_communication: 'Communication aux candidats',
      features_enabled: 'Services engagés',
      conversion: 'Conversion',
      citation: 'Convocation',
      user_activity: 'Activité des utilisateurs',
      ai_generative_evaluation: 'Évaluation IAG',
      publish_offers: 'Publication des offres',
      workflow: 'Workflow',
      vacant_management: 'Gestion des postes à pourvoir',
      all: 'Voir tous'
    },
    widgets: {
      interviewed_candidates: 'Nº de candidatures reçues',
      suitable_vs_interviewed: 'Aptes vs interviewés',
      interested_offer_candidates: 'Intéressés par les offres',
      updated_candidates: 'Candidats mis à jour',
      candidates_by_recruitment_source: 'Candidats évalués par source de recrutement',
      candidates_by_week_activity: 'Candidats interviewés par jour de la semaine',
      candidatures: 'Candidatures',
      whatsapp_consume_by_service: 'Consommation de WhatsApp',
      whatsapp_consumption: 'Utilisation de WhatsApp',
      candidates_per_phase: 'Candidats par phase',
      candidates_per_state: 'Nº de candidats par état',
      applied_vs_hired_per_position: 'Candidats inscrits vs. engagés par poste',
      interviewed_candidates_per_position: 'Candidats interviewés par poste',
      push_offers_total: 'Offres push lancées',
      people_deleted: 'Personnes supprimées',
      push_offers_candidates_notified: 'Offres push candidats notifiés',
      pull_candidates_notified: 'Pull candidats notifiés',
      hired_vs_applied_percentage: 'Candidats engagés vs. inscrits',
      total_sms_and_whatsapp_sent: 'Nombre de SMS/Whatsapp envoyés',
      total_email_sent: 'Nombre d\'emails envoyés',
      candidates_with_citation_sent: 'Nombre de candidats convoqués à un entretien',
      average_time_between_citation_and_interview: 'Temps moyen écoulé (en jours) entre l\'envoi de convocation et l\'entretien',
      hired_per_recruitment_source: 'Candidats engagés par origine de recrutement',
      features_enabled_check: 'Fonctionnalités engagées',
      suitable_per_recruitment_source: 'Aptes à un entretien par origine de recrutement',
      communication_methods_enabled: 'Canaux de communication avec les candidats',
      citations_accepted_percentage_per_position: 'Convocations acceptées par poste',
      candidates_per_region: 'Répartition géographique des candidats',
      candidates_per_region_spain: 'Candidats par ville (Espagne)',
      registered_vs_suitable_by_recruitment_source: 'Conversion apte vs. interviewé par origine de recrutement',
      suitable_vs_no_suitable: 'Candidats aptes vs. inaptes',
      suitable_vs_registered: 'Candidats aptes vs. inscrits',
      citations_response_pct: 'Pourcentage de réponse aux convocations',
      citations_accepted_pct: 'Pourcentage de convocations acceptées',
      pull_interested_percentage: 'Offres pull intéressées par l\'offre',
      push_interested_percentage: 'Offres pull intéressées par l\'offre',
      candidate_experience_cnps: 'Satisfaction expérience candidat (CNPS)',
      interviews_by_hour_of_day: 'Entretiens par heure du jour',
      interviews_by_hour_of_day_pie: 'Entretiens par heure du jour (circular)',
      average_candidate_experience: 'Satisfaction expérience candidat (CNPS)',
      candidates_by_recruitment_source_absolute: 'Candidats évalués par source de recrutement',
      average_time_to_hire: 'Délai moyen d’embauche',
      average_phase_time: 'Temps moyen (en jours) par phase',
      ats_access_count: 'Nombre d\'accès à ATS',
      ats_unique_access_count: 'Utilisateurs avec activité dans ATS par rapport au total',
      ats_access_count_ranking: 'Ranking des utilisateurs avec le plus d\'accès',
      ats_most_browsed_sections: 'Sections les plus visitées',
      ats_status_changed_count: 'Changements de statut par l\'utilisateur',
      whatsapp_voucher_consumed: 'Bons consommés Whatsapp',
      funnel_applied_sent_opened_interviewed_by_sms_what: 'Funnel (Inscrits, envoyés, ouverts, interviewés) par Whatsapp/SMS',
      applied_vs_interviewed_conversion_sms_whatsapp: 'Nombre d\'inscrits vs. interviewés par Whatsapp/SMS',
      funnel_applied_sent_opened_interviewed_by_email: 'Funnel (Inscrits, envoyés, ouverts, interviewés) par email',
      applied_vs_interviewed_conversion_email: 'Nombre d\'inscrits vs. interviewés par email',
      funnel_applied_sent_opened_interviewed_by_unique: 'Funnel (Inscritos, enviados, abiertos, entrevistados) uniques',
      applied_vs_interviewed_conversion_unique: 'Nombre d\'inscrits vs. interviewés uniques',
      conversion_by_candidature: 'Ratio de conversion par candidature',
      suitable_vs_not_suitable_pie: 'Aptes vs inaptes',
      conversion_by_recruitment_source: 'Ratio de conversion par origine de recrutement',
      people_by_client: 'Candidats uniques',
      top_churns_by_chatbot: 'Points de pertes principales par chatbot',
      gpp_created_process: 'Processus créés',
      gpp_processes_summary: 'Situation des processus',
      gpp_processes_by_type: 'Processus couverts automatiquement et manuellement',
      gpp_processes_by_reason: 'Processus demandés par motif',
      gpp_average_candidates_per_process: 'Candidats suggérés en moyenne par poste',
      gpp_uncovered_processes_automatically: 'Processus couverts manuellement',
      gpp_requests_by_user: 'Demandes par demandeur',
      gpp_requests_by_validator: 'Demandes par validateur',
      gpp_requests_by_area: 'Demandes par zone',
      gpp_candidates_by_gender: 'Candidats suggérés par gente',
      gpp_time_to_hire_global: 'Time to hire global',
      gpp_time_to_hire_by_process: 'Time to hire par processus',
      gpp_lifetime_processes: 'Temps de gestion des processus',
      gpp_average_management_time_by_user: 'Temps de gestion moyen par candidat',
      hired_candidates_by_gender: 'Candidats embauchés par genre',
      female_candidates_per_phase: 'Candidates féminines par phase',
      female_candidates_per_selection_status: 'Candidates féminines par état',
      male_candidates_per_phase: 'Candidats masculins par phase',
      male_candidates_per_selection_status: 'Candidats masculins par état',
      average_positions_per_candidate: 'Moyenne de candidatures par personne',
      tableColumns: {
        phase: 'Phase',
        state: 'État',
        offer: 'Offre',
        notified: 'Notifiés',
        interested: 'Intéressés',
        position: 'Poste',
        hired: 'Engagés',
        applied: 'Inscrits',
        feature: 'Fonctionnalité',
        enabled: 'Actif',
        candidates: 'Candidats',
        recruitment_source: 'Origine',
        activity: 'Activité',
        interviews: 'Entretiens',
        hours: 'Heure du jour',
        method: 'Méthode',
        accepted: 'Acceptés',
        percentage: '%',
        email: 'Utilisateur',
        total: 'Accès',
        chatbot: 'Chatbot',
        chatbot_node: 'Nœud',
        chatbot_node_text: 'Question',
        sessions: 'Total sessions',
        churn: 'Nombre d\'abandons',
        churn_pct: 'Pourcentage d\'abandons',

        uncovered_position: 'Poste',
        uncovered_center: 'Centre',
        num_uncovered: 'Processus non couverts',
        num_covered_manually: 'Nombre de processus',

        gpp_solicitor: 'Demandeur',
        gpp_solicitor_name: 'Demandeur',
        gpp_cp_name: 'Poste de travail',
        gpp_cs_position: 'Position',
        gpp_vr_name: 'Nom du processus',
        gpp_vr_closed_time: 'Journées de gestion',
        gpp_vr_avg_closed_time: 'Moyenne de gestion (jours)',
        gpp_num_requests: 'Processus créés',
        gpp_pending_pct: 'En cours d\'approbation',
        gpp_cancelled_pct: 'Annulés',
        gpp_rejected_pct: 'Rejetés',
        gpp_active_pct: 'En cours',
        gpp_empty_pct: 'Vides',
        gpp_inactive_pct: 'Expirés',
        gpp_closed_pct: 'Clos',

        gpp_pending: 'En cours d\'approbation',
        gpp_cancelled: 'Annulés',
        gpp_rejected: 'Rejetés',
        gpp_active: 'En cours',
        gpp_empty: 'Vides',
        gpp_inactive: 'Expirés',
        gpp_closed: 'Clos',

        gpp_validator: 'Valideur',
        gpp_num_approved: 'Approuvés',
        gpp_num_rejected: 'Rejetés',

        gpp_division: 'Division',
        gpp_area: 'Secteur',
        gpp_subarea: 'Sous-division',
        gpp_unit: 'Centre',
        gpp_position: 'Poste',
        time_to_hire: 'Nombre de jours pour clore le processus'
      }
    }
  },
  templateRequestManager: {
    cloneProcess: 'Cloner processus',
    pendingValidation: "En attente de validation",
    reviewed: 'Vérifiés',
    requestor: 'Demandeur',
    emptyDataSource: 'Il n\'existe pas encore de candidats disponibles pour ce processus. Le service de sélection se chargera de le gérer au plus tôt',
    of: 'de',
    emptyData: 'Toujours sans contenu',
    rowsPerPage: 'Lignes par page',
    emptyForm: 'Des champs obligatoires n\'ont pas été remplis',
    titleSection: 'Gestionnaire de demande de modèle',
    descriptionSection:
      'Créez, centralisez et organisez les demandes du centre de travail de manière efficace, rapide et ordonnée pour gérer les besoins de sélection.',
    createProcess: 'Créer processus',
    filterProcess: 'Filtrer processus',
    unit: 'Unité',
    dateCreated: 'Date de création',
    fullname: 'Prénom et nom',
    favorites: 'Favoris',
    yearExperience: 'Années d\'expérience',
    levelEducation: 'Niveau d\'études',
    dateValidation: 'Date de validation',
    cv: 'CV',
    phone: 'Téléphone',
    email: 'E-mail',
    address: 'Adresse',
    profile: 'Profil',
    skills: 'Compétences',
    match: 'Coïncidences souhaitées',
    phase: 'Phase',
    status: 'Statut',
    reason: 'Raison',
    lastDisplayDateAt: 'Dernière consultation',
    lastCandidatureChangeStatusDateAt: 'Dernier changement de statut',
    lastAppointment: 'Dernière convocation',
    dateIncorporation: 'Date de début',
    position: 'Poste',
    shift: 'Équipe',
    typeContract: 'Type de contrat',
    vacancies: 'Postes à pourvoir',
    proposedCandidates: 'Candidats proposés',
    interviewedCandidates: 'Candidats interviewé',
    statusProcess: 'Statut du processus',
    newStatusProcess: 'Statut actuel',
    processOpened: 'Processus',
    buttonOpenedProcess: 'Ouverts',
    buttonClosedProcess: 'Clos',
    newProcess: 'Nouveau processus',
    showProcess: 'Détails du processus',
    candidates: 'Candidats',
    dependent: 'Vendeur/vendeuse',
    numberVacancies: 'Nombre de postes à pourvoir',
    numberVacanciesRequest: 'Nombre de postes à pourvoir demandés',
    descriptionNewProcess:
      'Commencez un nouveau processus. Personaliza aspectos cruciales como jornada, requisitos y tipo de contrato, entre otros. Définissez les critères qui permettront d\'identifier les candidats appropriés pour ce processus de sélection',
    backToTemplateRequestManager: 'Retour au gestionnaire de demande de modèle',
    placeholderPosition: 'Sélectionnez le poste',
    placeholderTypeContract: 'Sélectionnez le type de contrat',
    placeholderWorkplace: 'Sélectionnez le centre de travail',
    placeholderShift: 'Sélectionnez l\'équipe',
    placeholderRequirementsRequired: 'Indiquez les exigences obligatoires',
    placeholderRequirementsDesirable: 'Indiquez les exigences souhaitables',
    placeholderObservations: 'Indiquez vos remarques',
    workplace: 'Centre de travail',
    requeriments: 'Exigences',
    desirableRequirements: 'Exigences souhaitables',
    mandatoryRequirements: 'Exigences obligatoires',
    observations: 'Remarques',
    saveProcess: 'Sauvegarder',
    titleSectionValidator: 'Validation des processus',
    descriptionSectionValidator:
      'Validez les processus de sélection qui ont été créés par les gestionnaires de demande de modèle. Vérifiez les détails de chaque processus et approuvez ou rejetez la demande.',
    statusProcessValidator: 'Statut du processus',
    typeContractIndefinite: 'Indéfini',
    typeContractTemporary: 'Temporaire',
    typeContractInterim: 'Vacataire',
    workingDayFullTime: 'Complète',
    workingDayPartTime: 'Partielle',
    workingDayHourly: 'Par heures',
    shiftMorning: 'Matin',
    shiftAfternoon: 'Après-midi',
    shiftFullTime: 'Complète',
    shiftNight: 'Nuit',
    shiftWeekends: 'Weekends',
    vacancyStates: {
      cancelled: 'Processus annulé',
      rejected: 'Processus rejeté',
      active: 'Processus approuvé',
      closed: 'Processus clos',
      pending: 'En cours d\'approbation',
      empty: 'En attente de présélectrion manuelle',
      inactive: 'Processus expiré'
    },
    vacancyReasons: {
      gap: 'GAP',
      replacement: 'Substitution',
    },
    vacancyContract: {
      1: 'Contrat indéfini',
      2: 'Contrat temporaire'
    },
    workingDayLabel: 'Journée de travail',
    placeholderWorkingDay: 'Sélectionnez la journée de travail',
    workingDay: {
      full_time: 'Complet',
      part_time: 'Partiel',
      weekend: 'Week-end',
    },
    workShiftLabel: 'Quart de travail',
    placeholderWorkShift: 'Sélectionnez le quart de travail',
    workShift: {
      morning_shift: 'Demain',
      swing_shift: 'En retard',
      night_shift: 'Soirée',
      rotating_shift: 'Rotatif',
      split_shift: 'Jeu',
      full_shift: 'Complet',
      intensive_shift: "Intensif",
    },
    contractHoursLabel: 'Heures/semaine',
    placeholderContractHours: 'Sélectionnez les heures par semaine',
    contractHours: {
      40: '40 heures par semaine',
      37: '37 heures par semaine',
      35: '35 heures par semaine',
      30: '30 heures par semaine',
      20: '20 heures par semaine',
      16: '16 heures par semaine',
      15: '15 heures par semaine',
      12: '12 heures par semaine',
      10: '10 heures par semaine',
      8: '8 heures par semaine',
    },
    searchMode: 'Collage',
    searchModes: {
      direct: 'Affectation directe',
      algorithm: 'Algorithme',
      repush: 'Déclin'
    },
    actions: 'Actions',
    acceptProcess: 'Accepter processus',
    rejectProcess: 'Rejeter processus',
    cancelProcess: 'Annuler le processus',
    cancelProcessTooltip: 'Si vous ne souhaitez pas poursuivre le processus, son annulation entraînera la libération des candidats et la clôture du processus.',
    closeProcess: 'Fin du processus',
    closeProcessTooltip: 'Si le processus a abouti à l’obtention des embauches souhaitées, l’arrêt du processus entraînera sa conclusion et ne pourra pas être rouvert.',
    repushCandidatesTooltip: 'Remplissez le processus avec les nouveaux candidats trouvés jusqu\'à ce que le processus soit terminé.',
    repushCandidates: 'Remplissez les candidats',
    repushCandidatesSuccess: 'La demande de recherche de nouveaux candidats a été exécutée avec succès',
    repushCandidatesFullfilled: 'La demande de modèle comporte déjà le nombre maximum de candidats autorisés.'
  },

  "IAGen": {
    "criterias": {
      "experience": "Expérience",
      "EXPERIENCIA": "Expérience",

      "education_training": "Éducation / Formation",
      "ESTUDIOS": "Éducation / Formation",

      "salary_expectation": "Attentes salariales",
      "SALARIO": "Attentes salariales",

      "employment_status": "Statut professionnel",
      "SITUACION_LABORAL": "Statut professionnel",

      "location": "Localisation",
      "UBICACION": "Localisation",

      "mobility": "Mobilité",
      "MOVILIDAD": "Mobilité",

      "availability": "Disponibilité",
      "DISPONIBILIDAD": "Disponibilité",

      "others": "Autres",

      "not_evaluated": "Non évalué"
    }
  }
}
