import { createStyles } from '@material-ui/core/styles'

export default theme =>
  createStyles({
    '@global': {
      body: {
        backgroundColor: theme.colors.pageBackground,
        minWidth: 320
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
      borderRadius: '30px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 20
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: 'transparent',
      padding: 40
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      position: 'relative'
    },
    progress: {
      color: theme.colors.greyAlpha25,
      position: 'absolute',
      right: 20
    },
    customNotchedOutline: {
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0176BC !important',
      },
    },
    customLabelFocused: {
      color: '#0176BC !important',
    },
  })
