import React from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styles from './styles'
import logoFull from '../../../assets/images/logo-velora-classic.svg';
import logoIsotype from '../../../assets/images/velora-isotipo-blanco.svg';

class DrawerMenuLogo extends React.Component {
  render() {
    const { onClick, classes, client, isSidebarOpen } = this.props
    return (
      <Button onClick={onClick} className={classes.root}>
        <img
          src={isSidebarOpen ? logoFull : logoIsotype}
          alt="Velora logo"
          className={isSidebarOpen ? classes.logoFull : classes.logoIsotype} />
      </Button>
    )
  }
}

DrawerMenuLogo.propTypes = {
  active: PropTypes.bool,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  client: PropTypes.object,
}

export default withStyles(styles)(DrawerMenuLogo)



