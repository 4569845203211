export default {
  generalError: 'Ha ocurrido un error',
  update: 'Actualizar',
  showMore: 'Mostrar más +',
  showLess: 'Mostrar menos -',
  markAs: 'Marcar como',

  genericText: {},

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Aplicar'
  },

  regularSelect: {
    select: 'Seleccionar'
  },

  modalHeader: {
    planning: 'Planificación'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Eliminar',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre del candidato',
    filterProfiles: 'Filtrar perfiles',
    remove: 'Eliminar'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Añadir a la lista negra a ',
    removeFromBlacklist: 'Eliminar de la lista negra a ',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre del candidato',
    filterProfiles: 'Filtrar perfiles',
    add: 'Añadir',
    remove: 'Eliminar'
  },

  modalChangeStatus: {
    changeStatus: 'Cambiar estado',
    selectStatus: 'Selecciona un estado',
    select: 'Seleccionar',
    suitable: 'Apto',
    not_suitable: 'No apto',
    to_be_confirmed: 'Por confirmar',
    confirmed: 'Confirmado',
    not_interested: 'No interesado',
    reschedule: 'Reprogramar',
    hired: 'Contratado',
    booking: 'Reserva',
    not_attend: 'No asiste',
    cited_phase2: 'Citado fase 2',
    pending_update: 'Actualización pendiente',
    discarded: 'Descartado',
    descarded_phase2: 'Descartado fase 2',
    not_interested_phase2: 'No interesado fase 2',
    not_attend_phase2: 'No asiste fase 2',
    in_process: 'En proceso',
    hiredDate: 'Fecha de alta ',
    in_progress: 'En progreso',
    doc_required: 'Doc solicitada',
    doc_received: 'Doc recibida'
  },

  modalChangeTags: {
    title: 'Cambiar etiquetas'
  },

  favoritesTable: {
    date: 'Fecha',
    markAsRead: 'Marcar como leído',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    fav: 'Favorito',
    export: 'Exportar',
    profile: 'Scoring profile',
    skills: 'Scoring skills',
    matching: 'Matching total',
    status: 'Estado',
    evaluation: 'Evaluación',
    personalData: 'Datos personales'
  },

  favoritesProcessTable: {
    title: 'Procesos favoritos'
  },

  favoritesOnboardingTable: {
    title: 'Onboarding favoritos'
  },

  processesTable: {
    title: 'Inbox procesos',
    id: 'Proceso',
    name: 'Nombre y apellidos',
    prominent: 'Destacado',
    date: 'Fecha y hora',
    department: 'Departamento, área',
    category: 'Categoría',
    type: 'Tipo',
    contact: 'Email',
    tel: 'Teléfono',
    status: 'Estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favorito',
    print: 'Imprimir',
    markAsRead: 'Marcar como leído',
    unmarkAsRead: 'Marcar como no leído',
    filterProcesses: 'Filtrar procesos',
    changeStatus: 'Cambiar estado',
    changeTags: 'Cambiar etiquetas',
    offer: 'Enviar oferta',
    offerCandidatureState: 'Interés',
    export: 'Crear Informe',
    all: 'Todos',
    talent_acquisition: 'candidaturas seleccionadas',
    on_board: 'empleados seleccionados',
    welcome: 'empleados seleccionados',
    employee_line: 'empleados seleccionados',
    feedback: 'empleados seleccionados',
    exit_interview: 'empleados seleccionados',
    offer_sent: 'Ofertas enviadas'
  },

  offerModal: {
    title: 'Crear oferta push',
    name: 'Nombre de la oferta',
    name_placeholder: 'Repartidor Aranjuez',
    description: 'Descripción',
    description_placeholder: 'Necesitamos una persona que tenga 2 años de experiencia y con vehiculo para la zona de Aranjuez',
    date_range: 'Fecha inicio/fin de la oferta',
    areUSure: {
      description: 'Vas a notificar a {num} persona/s sobre esta oferta, ¿Estás seguro?',
      sendCompleted: 'Oferta Enviada'
    }
  },

  process: {
    title: 'Procesos',
    category: 'Categoría',
    department: 'Departamento',
    service: 'Servicio',
    process: 'Proceso',
    comments: 'Comentarios',
    date: 'Fecha',
    time: 'Hora',
    comment: 'Comentario',
    author: 'Autor',
    documentation: 'Documentación aportada',
    previewCV: 'CV del candidato',
    noPreview: 'Preview no disponible',
    downloadDocs: 'Descargar seleccionados',
    deleteDocs: 'Eliminar seleccionados',
    uploadDocs: 'Subir documentos',
    createComment: 'Crear comentario',
    text: 'Texto',
    commentName: 'Comentario',
    dayHourTransaction: 'Fecha y hora transacción',
    area: 'Área',
    centerProvince: 'Centro Provincia',
    centerType: 'Tipo de centro',
    registerData: 'Fecha de alta',
    noDocuments: 'No hay documentos',
    modifiedStatus: 'Estado modificado',
    deleteSuccess: 'El proceso se ha eliminado con éxito',
    deleteModalTitle: 'Atención!',
    deleteModalText: '¿Estás seguro de que quieres eliminar este elemento?',
    remove: 'Eliminar'
  },

  exit_interview: {
    exit_reasons: 'Motivos de salida',
    exit_factors: 'Factores de salida',
    other_data: 'Otros datos',
    position: 'Puesto',
    leaving_date: 'Fecha de salida',
    male_or_female: 'Sexo',
    male: 'Hombre',
    female: 'Mujer',
    category: 'Categoría',
    email: 'Email',
    dni: 'DNI',
    phone: 'Tel',
    Motivo1: 'Motivo de salida',
    Motivo2: 'Submotivo de salida',
    Motivo3: 'Observaciones motivo de salida',
    Clima1: 'La relacion con el responsable ha sido',
    Clima2: 'El clima de trabajo ha sido satisfactorio',
    Concilia1: '¿Me han dado facilidades de cambio de turnos?',
    Concilia2: '¿Cómo crees que podríamos mejorar?',
    Desarrollo1: '¿La empresa le ha dado opciones de desarrollo?',
    Desarrollo2: '¿La formación recibida ha cumplido sus expectativas?'
  },

  feedback: {
    title: 'Inbox Items',
    filter: 'Filtrar Items',
    date: 'Fecha',
    questions: 'Preguntas',
    proposal: 'Item',
    category: 'Categoría',
    email: 'Email',
    dni: 'DNI',
    phone: 'Teléfono'
  },

  modalAnnouncement: {
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Convocatoria',
    date: 'Fecha de envío',
    citationStatus: 'Respuesta',
    citationDate: 'Fecha de citación',
    citationHour: 'Hora de citación',
    interviewer: 'Entrevistador',
    location: 'Ubicación',
    select: 'Seleccionar',
    announcement_details: 'Detalles',
    writeDetails: 'Especifica los detalles de la convocatoria',
    writeDetailsIntegration: 'Especifica los detalles de la convocatoria (no podrá ser modificado una vez creado el evento)',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    sendCitation: 'Enviar Citación',
    updateCitation: 'Actualizar',
    writeLocation: 'Escribe el lugar',
    writeInterviewer: 'Escribe el nombre del entrevistador',
    new_event: 'Asunto',
    invite_people: 'Asistentes opcionales',
    invite_people_placeholder: 'Incluye los correos separados por ;',
    to_time: 'hasta',
    online_meeting: 'Reunión online',
    attendees: 'Asistentes',
    optionals: 'Opcionales',
    accepted: 'Aceptada',
    declined: 'Rechazada',
    none: 'Sin respuesta',
    organizer: 'Organizador',
    notes: 'Notas',
    confirmation: {
      description_create_individual: 'Vas a crear una convocatoria individual, ¿Estás seguro?',
      description_create_group: 'Vas a crear una convocatoria grupal para {num} personas, ¿Estás seguro?',
      description_update: 'Vas a editar convocatoria para {num} persona/s, las convocatorias originales se sobrescribirán, ¿Estás seguro?',
      description_update_one: 'Ya hay una convocatoria existente, los datos se sobrescribirá, ¿Estás seguro?'
    },
    filterBlockCitation: 'Citación',
    filterBlockGeneral: 'General',
    responsible: 'Responsable'
  },

  modalRequireDoc: {
    requireDoc: 'Solicitar documentación',
    limitDate: 'Fecha límite para aportar la documentación',
    select: 'Seleccionar',
    infoToUpload: 'Información a aportar',
    writeInfoToUpload: 'Especifica la información a aportar por el candidato',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    sendRequireDoc: 'Enviar'
  },

  modalMailCustom: {
    quoteCall: 'Enviar notificación',
    editQuoteCall: 'Editar convocatoria',
    subject: 'Asunto',
    writeSubject: 'Escribe el asunto',
    message: 'Mensaje',
    writeMessage: 'Escribe un mensaje',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    send: 'Enviar Notificación',
    sendCompleted: 'Notificación enviada',
    sendsCompleted: 'Notificaciones enviadas'
  },

  modalFilter: {
    search: 'Buscar',
    notResults: 'No hay resultados',
    filterProfiles: 'Filtrar perfiles',
    name: 'Nombre',
    namePlaceholder: 'Nombre del candidato',
    date: 'Fecha',
    fromDate: 'Desde',
    toDate: 'Hasta',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Fuente de reclutamiento',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'Desde',
    to: 'Hasta',
    inputTextHelper: 'busca mútiples concidencias. Ej: "Madrid;Barcelona"',
    matching: 'Matching',
    workingDay: 'Jornada',
    workingDayPlaceholder: '10 horas, 20 horas...',
    select: 'Seleccionar',
    availability: 'Disponibilidad',
    availabilityPlaceholder: 'Total, parcial...',
    actualStatus: 'Estado actual',
    actualStatusPlaceholder: 'Apto, No apto...',
    position: 'Posición',
    positionPlaceholder: 'Dependiente, Camarero...',
    academicFormation: 'Formación académica',
    academicFormationPlaceholder: 'Diplomado, Licenciado...',
    experience: 'Experiencia',
    experiencePlaceholder: '1 año, 2 años...',
    lastJob: 'Último puesto',
    lastJobPlaceholder: 'Dependiente, Camarero...',
    cityOfResidence: 'Dirección',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Datos adicionales',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Otros datos',
    desiredAreaPlaceholder: 'Centro, Usera...',
    saveSearch: 'Guardar búsqueda filtrada',
    status: 'Estado',
    planningFilters: 'Filtros de Planificación',
    candidatureFilters: 'Filtros del Candidato'
  },

  boxOtherInfo: {
    otherData: 'Otros datos',
    additionalInformation: 'Información complementaria',
    nearbyPlaces: 'Centros más cercanos'
  },

  boxEditableInfo: {
    title: 'Información editable',
    edit: 'Editar',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    success: 'Campos actualizados correctamente',
    error: 'Error actualizando campos'
  },

  editFieldAction: {
    title: 'Editar campo',
    empty: '(Sin valor)'
  },

  videoSentiments: {
    title: 'Analisis del video',
    titleValores: 'Sentimientos detectados',
    anger: 'Ira',
    contempt: 'Desprecio',
    disgust: 'Asco',
    fear: 'Miedo',
    happiness: 'Felicidad',
    neutral: 'Neutralidad',
    sadness: 'Tristeza',
    surprise: 'Sorpresa'
  },
  citations: {
    citationCreated: 'Citación creada',
    citationUpdated: 'Citación actualizada',
    citations_sent: 'Citationes enviadas',
    candidatures: 'Candidatos'
  },

  citationStatus: {
    rejected: 'Rechazado',
    accepted: 'Aceptado',
    replan: 'Replanificar',
    pending: 'Sin respuesta',
    tentative: 'Provisional'
  },

  requireDoc: {
    docRequired: 'Solicitud de documentación enviada',
    docRequest: 'Documentación solicitada'
  },
  interviewerNotes: {
    titleTab: 'Notas de la entrevista',
    edit: 'Editar notas',
    textareaPlaceholder: 'Notas',
    showOnPrint: 'Mostrar al imprimir',
    updatedAt: 'Modificado por {author}, el {date} a las {hour}'
  },

  headerProfileButtons: {
    back: 'Atrás',
    actions: 'Acciones',
    previous: 'Anterior',
    following: 'Siguiente',
    downloadCV: 'Descarga CV',
    downloadVideo: 'Vídeo adjunto',
    markAsRead: 'Marcar como leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Convocatoria',
    changeState: 'Cambiar estado',
    changeTags: 'Cambiar etiquetas',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar excel',
    remove: 'Eliminar',
    print: 'Imprimir',
    userDeleted: 'Usuario eliminado',
    files: 'Adjuntos',
    mailCustom: 'Enviar notificación',
    docs: 'Adjuntos',
    backToApplications: 'Volver a candidatura',
    add_to_blacklist: 'Añadir a lista negra',
    remove_from_blacklist: 'Sacar de lista negra',
    added_to_blacklist: 'Añadido a lista negra',
    removed_from_blacklist: 'Sacado de lista negra',
    backToProcess: 'Volver al proceso',
    backToEvaluativeTests: 'Volver a video entrevista'
  },

  modalDownloadFiles: {
    title: 'Adjuntos'
  },

  modalFilesPreview: {
    no_preview: 'Preview no disponible'
  },

  modalCreateReport: {
    createReport: 'Crear Informe',
    name: 'Nombre',
    reportName: 'Nombre del informe',
    date: 'Fecha',
    fromDate: 'Desde',
    toDate: 'Hasta',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Fuente de reclutamiento',
    matching: 'Matching',
    workingDay: 'Jornada',
    availability: 'Disponibilidad',
    actualStatus: 'Estado actual',
    position: 'Posición',
    positionPlaceholder: 'Dependiente, Camarero...',
    academicFormation: 'Formación académica',
    academicFormationPlaceholder: 'Diplomado, Licenciado...',
    experience: 'Experiencia',
    lastJob: 'Último puesto',
    lastJobPlaceholder: 'Dependiente, Camarero...',
    cityOfResidence: 'Dirección',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Datos adicionales',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Otros datos',
    desiredAreaPlaceholder: 'Centro, Usera...',
    removeUser: 'Eliminar Usuario',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre del candidato',
    filterProfiles: 'Filtrar perfiles',
    from: 'De',
    to: 'A',
    include: 'Incluir',
    selectAll: 'Seleccionar todos'
  },

  modalChangeAvatar: {
    title: 'Cambiar avatar',
    info: 'Arrastra una imagen o pulsa en la caja para buscarla',
    submit: 'Aceptar',
    dropFilesHere: 'Arrastra una imágen aquí, o pulsa para seleccionar archivos...',
    dropFilesActive: 'Imágen válida, suelta para continuar'
  },

  modalChangePass: {
    title: 'Cambiar contraseña',
    forceTitle: 'Para continuar, cambie la contraseña',
    info: 'Introduce la nueva contraseña y pulsa en Aceptar',
    submit: 'Aceptar',
    oldPass: 'Contraseña actual',
    newPass: 'Nueva contraseña',
    repPass: 'Repite la contraseña',
    errors: {
      passNotMatch: 'Las contraseñas no coinciden',
      passNotStrongEnough:
        'La contraseña debe tener mínimo 12 caracteres y debe incluir una combinación de mayúsculas, minúsculas, números y caracteres especiales.'
    }
  },

  boxProfileCite: {
    currentState: 'Estado actual',
    citationDate: 'Fecha citación',
    hiringDate: 'Fecha de contratación',
    citationManager: 'Responsable citación',
    recruitmentSource: 'Fuente de reclutamiento',
    tags: 'Etiquetas',
    offers: 'Ofertas enviadas',
    disponibilidad: 'Disponibilidad horaria:'
  },

  boxInfoHeader: {
    currentState: 'Estado actual',
    citationDate: 'Fecha citación',
    citationManager: 'Responsable citación'
  },

  modules: {
    title: 'Módulos',
    module1: 'Talent Acquisition',
    module2: 'On Boarding',
    module3: 'Welcome Pack',
    module4: 'Línea de empleado',
    module5: 'Absentismo',
    module6: 'Surveys',
    module7: 'Tutor visual',
    module8: 'interview interview'
  },

  myProfile: {
    title: 'Mi perfil',
    logOut: 'Cerrar la sesion',
    name: 'Nombre',
    surname: 'Apellidos',
    email: 'Email',
    id: 'Id',
    phone: 'Teléfono',
    profileUpdated: 'Perfil actualizado',
    avatarUpdated: 'Avatar actualizado',
    updatePass: 'Actualizar contraseña',
    update: 'Actualizar',
    passUpdated: 'Contraseña actualizada',
    passUpdatedError: 'No se ha actualizado la contraseña. Por favor, Compruebe que la contraseña es correcta.'
  },

  simpleDialog: {
    defaultMessages: 'Mensajes predeterminados',
    close: 'Cerrar'
  },

  saveSearchesTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    date: 'Fecha',
    fields: 'Campos de filtrado',
    backToSaveSearches: 'Volver a Búsquedas Guardadas'
  },

  pullConfig: {
    title: 'Pull de candidatos',
    candidatures: 'Candidatos notificados'
  },

  pullConfigTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    is_active: 'Activo',
    renotify_answered: 'Renotificar',
    interval_in_days: 'Periodicidad',
    days: 'días',
    last_time_execution: 'Último envío',
    filters: 'Campos de filtrado',
    from: 'Desde',
    to: 'hasta'
  },

  reportsTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    date: 'Fecha',
    fields: 'Campos de filtrado'
  },

  profileStats: {
    profile: 'Profile',
    skills: 'Skills'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Riesgo',
    e: 'Extroversión',
    g: 'Paciencia',
    n: 'Normas',
    a: 'Autocontrol'
  },

  modalSaveSearch: {
    title: 'Nombre para la búsqueda guardada',
    back: 'Volver al filtrado',
    enterName: 'Escribe un nombre',
    saveSearch: 'Guardar búsqueda filtrada'
  },

  planningTable: {
    markAsRead: 'Marcar como leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Editar convocatoria',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Acciones',
    personalData: 'Datos personales',
    fav: 'Favorito',
    export: 'Exportar',
    matching: 'Matching',
    citation: 'Citación',
    interviewer: 'Entrevistador',
    technical: 'Responsable',
    phase: 'Fase',
    statusPhase: 'Estado',
    citationStatus: 'Respuesta',
    status: 'Estado',
    atendees: 'Asistentes',
    location: 'Localización',
    date: 'Creación Cit.',
    availability: 'Disponibilidad',
    reason: 'Motivo',
    groupal: 'Grupal',
    view: 'Ver'
  },

  peopleTable: {
    markAsRead: 'Marcar como leído',
    markAsNotRead: 'Marcar como no leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Editar convocatoria',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Acciones',
    personalData: 'Datos personales',
    fav: 'Favorito',
    export: 'Exportar cv',
    exportCsv: 'Exportar Informe',
    requireDoc: 'Solicitar documentación'
  },

  landingOffer: {
    title: 'Hola',
    body: 'Tenemos una nueva <b>oferta de trabajo</b> que podría interesarte: ',
    offerInfo: 'Información de la oferta',
    interested: 'Me interesa',
    notInterested: 'No me interesa',
    notExist: 'No existe la oferta',
    expiredDescription: 'Lo sentimos, esta oferta ya ha finalizado. Le tendremos en cuenta para furturas oportunidades. <br>Muchas gracias por su interes.',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias por participar en nuestro proceso de selección'
  },

  landingPull: {
    title: 'Hola',
    interested: 'Me interesa',
    notInterested: 'No me interesa',
    notExist: 'Página no encontrada',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias'
  },

  landingDocRequest: {
    title: 'Adjunta la información solicitada: ',
    limit_date: 'Fecha límite: ',
    notExist: 'Página no encontrada',
    expiredDescription: 'Lo sentimos, el plazo para aportar la información ya ha finalizado. <br>Muchas gracias por su interes.',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido la información. <br>¡Muchas gracias!'
  },

  dropzone: {
    dragOrBrowse: 'Arrastra ficheros o haz click para seleccionarlos',
    addFile: 'Añadir fichero',
    send: 'Enviar',
    error: 'Error',
    file_too_large: 'Archivo demasiado grande',
    file_max_size: 'Tamaño máximo de archivo',
    done: 'Enviado'
  },

  observationsTable: {
    today: 'Hoy',
    now: 'Ahora',
    title: 'Histórico de cambios',
    date: 'Fecha',
    time: 'Hora',
    change: 'Cambio',
    author: 'Autor',
    addPlaceholder: 'Añadir aquí una nueva observación',
    add: 'Añadir',
    interviewDate: 'Fecha entrevista',
    addObservation: 'Añadir observaciones'
  },

  pushOfferTable: {
    title: 'Ofertas Push',
    name: 'Nombre',
    description: 'Descripción',
    userName: 'Usuario',
    createdAt: 'Fecha creación',
    state: 'Estado'
  },

  offerCard: {
    title: 'Ofertas Push',
    interested_candidatures: ' candidatos interesados',
    update_at: 'Fecha',
    empty: 'Actualmente no hay ninguna oferta push',
    showOffers: 'Mostrar sólo mis ofertas push'
  },

  //Organism
  appBar: {
    searchPeople: 'Buscar perfiles',
    filterPeople: 'Filtrar candidaturas',
    filterPlanning: 'Filtrar planificación',
    saveSearchs: 'Guardar búsquedas',
    saveSearch: 'Guardar búsqueda',
    cloneSearch: 'Clonar búsqueda',
    noResults: 'Sin Resultados',
    errorNoTitle: 'El título no puede estar vacío',
    filters: 'Filtros',
    successSave: 'Búsqueda guardada'
  },

  profileInfo: {
    profile: 'Perfil',
    availableFrom: 'Disponible desde:',
    createdAt: 'Fecha de entrevista',
    updatedAt: 'Última actualización',
    pullSentAt: 'Envío de pull',
    zone: 'Zona geográfica',
    formation: 'Formación académica',
    exp: 'Experiencia',
    lastJob: 'Último puesto',
    city: 'Dirección',
    desiredLocation: 'Datos adicionales',
    desiredZone: 'Otros datos',
    years: 'años',
    cv: 'CV',
    video: 'Vídeo',
    attached: 'Adjunto',
    evaluation: 'Evaluación',
    selectionStatus: 'Editar estado actual'
  },

  drawer: {
    jobOffers: 'Jobs',
    inboxPeople: 'Candidatos',
    inbox: 'Candidatos',
    savedSearches: 'Busquedas guardadas',
    planning: 'Planificación',
    favorites: 'Favoritos',
    reports: 'Informes',
    statistics: 'Dashboard',
    dashboard: 'Dashboard',
    modules: 'Módulos',
    clients: 'Clientes',
    employeeLine: 'Linea empleado',
    talent: 'Captación de Talento',
    pushOffer: 'Ofertas Push',
    pullConfig: 'Configuración Pull',
    onboarding: 'Onboarding',
    feedback: 'Sugerencias',
    exit_interview: 'Entrevista de salida',
    integrations: 'Calendarios',
    my_profile: 'Mi perfil',
    userManual: 'Manual de uso ATS',
    phases_states: 'Fases y estados',
    evaluativeTests: 'Videoentrevistas',
    user_management: 'Usuarios',
    tag_management: 'Etiquetas',
    keywords_rpa: 'Jobsites',
    partners: 'Partners',
    superadministratorManual: 'Manual Administrador ATS',
    iaEvaluation: 'Evaluación IA',
    templateRequestManager: 'Gestor de petición de plantilla',
    validatorTemplateRequest: 'Validación de procesos'
  },

  statisticsItems: {
    checkAll: 'Marcar todos',
    year: 'Año',
    month: 'Mes',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    interviewed: 'Candidatos entrevistados',
    suitables: 'Aptos vs entrevistados',
    offer_interested: 'Interesados en ofertas',
    recovered: 'Candidatos actualizados',
    recruitmentSource: 'Candidatos por fuente de reclutamiento',
    activity: 'Actividad de candidatos entrevistados',
    startDate: 'Desde',
    endDate: 'Hasta',
    candidatures: 'Candidaturas',
    name: 'Nombre',
    surname: 'Apellidos',
    dni: 'DNI',
    numberIdentity: 'Nº Empleado',
    hiringDate: 'Fecha contratación',
    leavingDate: 'Fecha baja',
    employeesWithoutPhase1: 'Personas que no han realizado ninguna fase del onboarding',
    employeesHaveNotDoneExit: 'Empleados que no han realizado la entrevista de salida',
    candidaturesSuitables: 'Candidaturas aptas',
    hiredVsSuitables: 'Contratados vs aptos',
    notInterestedVsSuitables: 'No interesados vs aptos',
    tableTitle: 'Posiciones',
    onboarding_phases: 'Personas activas en cada fase del onboarding',
    phases: 'Fases',
    zone: 'Zona',
    whatsappCounter: {
      title: 'Contador de WhatsApp',
      section: 'Sección',
      bot_starter: 'Invitaciones entrevista',
      citations: 'Citaciones',
      offers: 'Ofertas push',
      pulls: 'Pull de candidatos',
      sent: 'Enviados',
      voucher: 'Bono'
    }
  },

  //Page
  inboxPeople: {
    title: 'Candidatos',
    filtersSearch: 'Resultados para'
  },

  offerCandidates: {
    title: 'Oferta: ',
    offers_sent: 'Ofertas enviadas',
    unique_candidatures: 'Candidatos únicos',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'WhatsApp'
  },

  favorites: {
    title: 'Favoritos'
  },

  planning: {
    title: 'Planificación'
  },

  reports: {
    title: 'Informes',
    reportCreated: 'Informe creado',
    reportDeleted: 'Informe borrado',
    modalConfirmationTitle: 'Generar informe',
    modalConfirmationButton: 'Aceptar',
    modalConfirmationButtonCancel: 'Cancelar',
    reportReceiversTitle:
      'Introduce los destinatarios del informe. Este proceso puede demorarse unos minutos. Te enviaremos un correo cuando este listo. Puedes añadir varios destinatarios separandolos por <b>;</b>'
  },

  savedSearches: {
    title: 'Búsquedas guardadas'
  },

  exitInterview: {
    title: 'Entrevistas de Salida'
  },

  statistic: {
    title: 'Estadísticas',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo'
  },

  errorPage: {
    title: 'Uuuups! Página no encontrada'
  },

  login: {
    login: 'Login',
    sigin: 'Acceder',
    email: 'Correo electrónico',
    password: 'Contraseña',
    recover: 'Recuperar',
    password_recover: 'Recuperar contraseña',
    password_recover_success: 'Se ha enviado una contraseña de recuperación al email indicado',
    password_recover_error: 'Ha habido un error en el envío del email de recuperación',
    mandatoryField: 'Campo obligatorio',
    invalidEmail: 'Email inválido',
    noValidRole: 'Usuario inválido',
    noValidCredentials: 'Usuario o contraseña incorrectos'
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elementos',
      labelRowsPerPage: 'Elementos por página',
      firstAriaLabel: 'Principio',
      firstTooltip: 'Ir al inicio',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Siguiente',
      nextTooltip: 'Siguiente',
      lastAriaLabel: 'Final',
      lastTooltip: 'Ir al final'
    },
    body: {
      emptyDataSourceMessage: 'Sin datos'
    },
    toolbar: {
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar'
    }
  },

  errors: {
    internalServerError: 'No se ha podido completar la operación.'
  },

  landingEmail: {
    title: 'Ha ocurrido un error',
    body: 'La operación no ha podido completarse',
    loading: 'Cargando',
    hi: 'Hola',
    citation_header:
      'Queremos informarte que tras evaluar tu perfil, nos gustaría avanzar contigo a la siguiente fase del proceso de selección para la posición de',
    citation_info: 'Información de la convocatoria',
    address: 'Dirección:',
    contact_person: 'Persona de contacto:',
    interview_date: 'Fecha de la entrevista:',
    interview_hour: 'Hora de la entrevista:',
    give_answer: 'Por favor, envíanos tu respuesta a esta convocatoria, seleccionando una de las siguientes opciones:',
    confirmed: 'Confirmo mi asistencia',
    reschedule: 'No puedo asistir, necesito otra cita',
    not_interested: 'No me interesa',
    citation_not_exist: 'No existe la citación'
  },

  landingReschedule: {
    title: '¿En qué franja horaria podrías acudir a una entrevista?',
    noDates: 'No hay fechas disponibles',
    successSend: 'Tu solicitud se ha enviado correctamente',
    putAvailability: 'Introduce disponibilidad',
    send: 'Enviar',
    reasonPlaceholder: 'Introduce el motivo'
  },

  landingNotInterested: {
    title: '¿Podrías indicarnos por qué no te interesa?',
    error: 'Ha ocurrido un error',
    successSend: 'Tu solicitud se ha enviado correctamente',
    putReason: 'Introduce el motivo',
    send: 'Enviar'
  },

  peopleTableFilters: {
    toManage: 'Por gestionar',
    inProgress: 'En proceso',
    finalists: 'Finalistas',
    others: 'Otros'
  },

  noCvInfo: 'Los candidatos seleccionados no tienen cv asociados',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Factores de salida',
      superAgree: 'Muy de acuerdo',
      agree: 'De acuerdo',
      littleDisagree: 'Poco de acuerdo',
      disagree: 'Nada de acuerdo',
      clima1: 'Buen clima en la empresa',
      clima2: 'Buen clima con tu equipo',
      concilia1: 'Permite conciliar con la familia',
      concilia2: 'Concilia con los estudios',
      desarrollo1: 'Remunerado acorde al trabajo',
      desarrollo2: 'Ofrece desarrollo profesional'
    },
    exits: 'Bajas',
    exit_completed: 'Finalizadas',
    recommends: 'Sí, recomienda',
    reason: 'Motivos de salida'
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Procesos transaccionales recibidos',
    suitables: 'Tramitados vs total',
    numberOfPolls: 'Encuestas respondidas',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Procesos transaccionales por tipos',
    processesConsultivoGroup: 'Procesos consultivos por tipos',
    processesCollectiveAgreementGroup: 'Procesos por convenio colectivo',
    startDate: 'Desde',
    endDate: 'Hasta',
    candidatures: 'Candidaturas',
    name: 'Nombre',
    processes: 'Procesos',
    recived: 'Recibidos',
    total: 'Total',
    suitablesVsRecibidos: 'En proceso vs total',
    tableTitle: 'Procesos transaccionales'
  },
  tableFilters: {
    toManage: 'Por gestionar',
    inProgress: 'En proceso',
    finalists: 'Finalistas',
    others: 'Otros'
  },
  onboardingInbox: {
    title: 'Inbox Onboarding'
  },
  onboarding: {
    deleteSuccess: 'El elemento se ha eliminado con éxito'
  },
  status: {
    received: 'Recibido',
    canceled: 'Cancelado',
    in_process: 'En proceso',
    pending_info: 'Pendiente de info',
    processed: 'Tramitado',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    declined: 'Rechazado',
    none: 'Sin respuesta',
    postponed: 'Aplazado',
    developing: 'En desarrollo',
    implemented: 'Implantado',
    1: 'Best match',
    2: 'Disponible',
    3: 'Por confirmar',
    4: 'Confirmado',
    5: 'No interesado',
    6: 'Reprogramar',
    7: 'Contratado',
    8: 'Reserva',
    9: 'No asiste',
    10: 'Citado fase 2',
    11: 'Descartado',
    12: 'Descartado fase 2',
    13: 'No interesado fase 2',
    14: 'No asiste fase 2',
    15: 'En proceso',
    16: 'Doc solicitada',
    17: 'Doc recibida',
    18: 'Actualización pendiente',
    19: 'Citado'
  },
  dialog: {
    success: 'Aceptar',
    decline: 'Cancelar',
    save: 'Guardar'
  },

  feedbackStatisticsItems: {
    Received: 'Sugerencias recibidas',
    suitables: 'Sugerencias en curso vs total',
    category: 'Sugerencias por categorías',
    department: 'Sugerencias por áreas',
    centerType: 'Sugerencias por unidad',
    statesTypes: 'Sugerencias por estados',
    endDate: 'Hasta',
    name: 'Nombre',
    recived: 'Recibidos',
    total: 'Total'
  },

  onboardingStatisticsItems: {
    leaving: 'Bajas',
    hiring: 'Incorporaciones',
    inprogress: 'Onboardings en progreso',
    ratio: 'Ratio',
    phase: 'Fase',
    hot_issues_title: 'Hot issues',
    hot_issues: {
      formacion_online: 'Formación online',
      uniforme: 'Uniforme disponible',
      epi: 'EPI disponible',
      contrato: 'Contrato entregado',
      rm: 'Reconocimiento médico',
      relacion_equipo: 'Mala relación con el equipo',
      formacion_riesprof_hito3: 'Formación prevención de riesgos',
      mutua_mc_mutual_hito3: 'Protocolo de actuación en caso de accidentes',
      formacion_compliance_hito3: 'Formación Compliance',
      reconocimiento_medico_hito3: 'Reconocimiento médico',
      welcomepack_hito3: 'Welcome Pack',
      employeeapp_hito3: 'Employee App',
      protocol_bajamed_hito4: 'Protocolo baja médica',
      reunion_manager_hito5: 'Reunión Feedback'
    }
  },

  offerStates: {
    unanswered: 'Sin contestar',
    interested: 'Interesado',
    not_interested: 'No interesado'
  },

  integrations: {
    connect: 'Conectar',
    disconnect: 'Desvincular'
  },

  userManual: {
    title: 'Manual de uso ATS'
  },

  phases_states: {
    descriptionPhasesStates: 'Desde aquí podrás crear, editar o eliminar las fases y los estados de las candidaturas.',
    titlePhase: 'Fases',
    titleState: 'Estados',
    descriptionPhase:
      'Las fases representan los diferentes pasos que un candidato atraviesa durante el proceso de selección. Las fases predefinidas son: Recibidos y Contratados.',
    descriptionTwoPhase:
      'Además de estas fases predefinidas, la aplicación te brinda la flexibilidad de crear fases personalizadas según las necesidades específicas de tu organización.',
    descriptionState:
      'Los estados en una fase representan el nivel de avance de un candidato dentro de una etapa específica del proceso de selección, lo que a su vez simplifica la identificación y seguimiento del progreso del candidato en esa fase. Los estados predefinidos son: Inscrito y Contratado',
    descriptionTwoState:
      'Sumado a los estados ya establecidos, la aplicación te permite personalizar estados para adecuarse perfectamente a las particularidades de tu organización.',
    labelPhase: 'Fase',
    labelState: 'Estado',
    labelCurrentState: 'Estado actual',
    labelNewState: 'Estado nuevo',
    errorOnDeleteStatus: 'No se puede eliminar un estado que está en uso, tiene {candidatures} candidaturas que deberá mover antes a otra estado.',
    placeholderPhase: 'Introduce el nombre de la fase',
    placeholderState: 'Introduce el nombre del estado',
    placeholderSelectPhase: 'Selecciona una fase',
    titleButtonPhase: 'Añadir fase',
    titleButtonState: 'Añadir estado',
    assignStateToPhase: 'Asignar estado a fase',
    buttonSave: 'Guardar',
    buttonAllSave: 'Guardar cambios',
    buttonCancel: 'Cancelar',
    buttonMove: 'Mover'
  },

  evaluativeTests: {
    title: 'Video entrevistas',
    descriptionSection: 'Aquí encontrarás todas tus video entrevistas activas e inactivas.',
    titleNewTest: 'Nueva video entrevista',
    titleEditTest: 'Editar video entrevista',
    subtitleNewTest: 'Configuración de la video entrevista',
    descriptionNewTest: 'Realiza la configuración, según la necesidades del proceso de selección',
    labelPickerStartDate: 'Fecha de inicio',
    labelPickerEndDate: 'Fecha de fin',
    nameTest: 'Nombre de la video entrevista',
    placeholderNameTest: 'Introduce el nombre de la video entrevista',
    urlBot: 'URL del bot',
    placeholderUrl: 'https://...',
    buttonGenerateUrl: 'Generar URL',
    selectionQuestions: 'Preguntas de selección',
    subtitleTest: 'Realiza un filtro más completo de los candidatos.',
    subtitleTest2: 'Crea al menos una pregunta y centrate en los candidatos que respondieron correctamente.',
    placeholderInputQuestion: 'Pregunta N°1',
    buttonAddQuestion: 'Añadir pregunta',
    buttonSave: 'Guardar',
    buttonCancel: 'Cancelar',
    buttonRegenerateUrl: 'Regenerar URL',
    noResults: 'No se encontraron resultados para la fecha seleccionada.',
    errorFetchTests: 'Ha ocurrido un error al obtener las video entrevistas ',
    buttonCreateTest: 'Crear video entrevista',
    singleQuestion: 'Pregunta',
    multipleQuestions: 'Preguntas',
    successDeleteTest: 'Video entrevista eliminada correctamente',
    successDuplicateTest: 'Video entrevista duplicada correctamente',
    successCreateTest: 'Video entrevista creada correctamente',
    successUpdateTest: 'Video entrevista actualizada correctamente',
    useSampleQuestion: 'Usar pregunta de ejemplo',
    activesTest: 'Activas',
    inactivesTest: 'Inactivas',
    titleBoxProfileInbox: 'Video entrevistas',
    statusVideoInterview: 'Estado de la videoentrevista',
    errorUploadVideo: 'Error en la subida del video',
    titleTemplate: 'Plantillas de bot',
    successChangeStatus: 'Estado cambiado correctamente',
    errorFieldRequired: 'Este campo es obligatorio',
    errorFieldInvalid: 'Este campo es inválido',
    errorFieldQuestion: 'Debes añadir al menos una pregunta',
    placeholderSelectTemplate: 'Selecciona una plantilla',
    placeholderTimeLimit: 'Tiempo max.',
    configQuestion: 'Debes configurar al menos una pregunta'
  }
}
